import { MOBILE_WIDTH } from 'const';
import React from 'react';
import {
  FEScrollableTitledBottomSheet,
  FEScrollableTitledDialog,
} from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { useNavigate } from 'react-router-dom';
import { Content } from './components';
import style from './components/content/style.module.css';
import { useLinks } from 'hooks/router';
import { AuthClient } from 'api/base';
import { useTranslation } from 'react-i18next';

const EditProfilePage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const navigate = useNavigate();

  const { authLink } = useLinks();

  React.useEffect(() => {
    if (AuthClient.isAuth === false) {
      navigate(authLink, { replace: true });
    }
  }, [authLink, navigate]);

  return isDesktop ? (
    <FEScrollableTitledDialog
      containerClassName={style.container}
      title={t('editProfile.title')}
      open={open}
      onClose={() => navigate(-1)}
      render={({ onClose }) => <Content onClose={onClose} />}
    />
  ) : (
    <FEScrollableTitledBottomSheet
      containerClassName={style.container}
      title={t('editProfile.title')}
      open={open}
      onClose={() => navigate(-1)}
      render={({ onClose }) => <Content onClose={onClose} />}
    />
  );
};

export default EditProfilePage;
