import {ConstructorCard, DishCard} from "pages/catalogDesktop/components";
import CatalogCardFactoryType from "pages/catalogDesktop/components/catalogCardFactory/CatalogCardFactoryType";
import {ConstructorInCatalog, DishInCatalog} from "domains/catalog";

const CatalogCardFactory = (
    {
        available,
        currency,
        key,
        className,
        stopLists,
        product,
        validationPayload,
        videoClassName,
        quantityInCart,
        changeQuantityInCart,
        type
    }
        : CatalogCardFactoryType) => {
    switch (type) {
        case "constructor": {
            return (
                <ConstructorCard
                    available={available}
                    currency={currency}
                    key={key}
                    className={className}
                    stopLists={stopLists}
                    product={product as ConstructorInCatalog}
                    validationPayload={validationPayload}

                />
            )
        }
        case "dish": {
            return (
                <DishCard
                    available={available}
                    currency={currency}
                    key={key}
                    className={className}
                    stopLists={stopLists}
                    dish={product as DishInCatalog}
                    validationPayload={validationPayload}
                    videoClassName={videoClassName}
                    quantityInCart={quantityInCart}
                    changeQuantityInCart={changeQuantityInCart}
                />
            )
        }
        default: {
            return null
        }
    }
}

export default CatalogCardFactory