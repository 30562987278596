import { OrdersValidateResponseV4 } from 'api/version4';
import clsx from 'clsx';
import {
  clearCart,
  ConstructorInCartModel,
  DishInCartModel,
} from 'domains/cart';
import { $deliveryZone } from 'domains/cartography';
import { useUnit } from 'effector-react';
import { useMount } from 'hooks';
import {
  changeConstructorQuantity,
  changeDishQuantity,
  deleteConstructor,
  deleteDish,
} from 'domains/cart';
import { useLinks } from 'hooks/router';
import { useRectObserver } from 'hooks/useRectObserver';
import { CurrencySymbol } from 'models';
import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FEButton, FEPortal } from 'ui-kit/components';
import style from './CartModal.module.css';
import { IMG_MODAL_SHOPPING_CART } from 'images';
import Product from '../product';

const calculatePosition = (
  targetPosition: DOMRect | null,
): CSSProperties | undefined => {
  if (!targetPosition) {
    return undefined;
  }

  const windowWidth = window.innerWidth;

  return {
    position: 'fixed',
    top: `${targetPosition.top + targetPosition.height + 11}px`,
    right: `${windowWidth - (targetPosition.left + targetPosition.width)}px`,
  };
};

const Layout = ({
  buttonRef,
  onClose,
  currency,
  dishes,
  constructors,
  isPending,
  price,
}: DataProps & Pick<ModalProps, 'onClose'>) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { cartLink } = useLinks();

  const [buttonPosition, setButtonPosition] = useState<DOMRect | null>(null);

  const [isAnimationIn, setIsAnimationIn] = useState(true);

  const firstRenderRef = useRef(true);

  const handleClose = () => {
    setIsAnimationIn(false);
    onClose();
  };

  const [
    handleChangeDishQuantity,
    handleDeleteDish,
    handleChangeConstructorQuantity,
    handleDeleteConstructor,
    handleClearCart,
  ] = useUnit([
    changeDishQuantity,
    deleteDish,
    changeConstructorQuantity,
    deleteConstructor,
    clearCart,
  ]);

  useEffect(() => {
    setIsAnimationIn(true);
    firstRenderRef.current = false;
  }, []);

  useRectObserver(buttonRef, setButtonPosition);

  const deliveryZone = useUnit($deliveryZone);

  const targetDeliveryZoneDeliveryPriceConditions =
    deliveryZone?.conditions.deliveryPriceConditions;

  const minDeliveryPrice =
    (targetDeliveryZoneDeliveryPriceConditions &&
      targetDeliveryZoneDeliveryPriceConditions.length &&
      Math.min(
        ...targetDeliveryZoneDeliveryPriceConditions.map(
          (condition) => condition.orderTotalMinimum,
        ),
      )) ||
    0;

  const emptyCartMessage = `${t('cart.emptyCart.description')}
  ${t('cart.emptyCart.caption', {
    deliveryPrice: minDeliveryPrice,
    currency,
  })}`;

  const handleNavigate = () => {
    navigate(cartLink);
    onClose();
  };

  const isCartEmpty = !dishes.length && !constructors.length;

  return (
    <>
      <div
        animation-in={isAnimationIn.toString()}
        animation-out={(!firstRenderRef.current && !isAnimationIn).toString()}
        className={style.overlay}
        onClick={handleClose}
      />
      <div
        role='dialog'
        aria-modal='true'
        style={calculatePosition(buttonPosition)}
        animation-in={isAnimationIn.toString()}
        animation-out={(!firstRenderRef.current && !isAnimationIn).toString()}
        className={style.cartModal}
      >
        <div className={style.header}>
          <p className={clsx('title', style.cartTitle)}>{t('Твой заказ')}</p>
          <FEButton
            type='secondary'
            size='medium'
            className={style.clearButton}
            showLoader={isPending}
            disabled={isCartEmpty || isPending}
            onClick={handleClearCart}
          >
            {t('common.clear')}
          </FEButton>
        </div>
        {isCartEmpty && (
          <>
            <img
              className={style.emptyCartImage}
              src={IMG_MODAL_SHOPPING_CART}
              alt=''
            />
            <p className={clsx('title', style.emptyCartTitle)}>
              {t('cart.emptyCart.title')}
            </p>
            <p className={clsx('text-2', style.emptyCartMessage)}>
              {emptyCartMessage}
            </p>
          </>
        )}
        {!isCartEmpty && (
          <>
            <div className={style.productsList}>
              {dishes.map((dish, index) => (
                <Product
                  currency={currency}
                  onChangeQuantity={(quantity) =>
                    handleChangeDishQuantity({ index, quantity })
                  }
                  onDelete={() => handleDeleteDish({ index })}
                  key={dish.orderItemId}
                  isValidating={isPending}
                  product={dish}
                  type='DISH'
                />
              ))}
              {constructors.map((constructor, index) => (
                <Product
                  currency={currency}
                  onChangeQuantity={(quantity) =>
                    handleChangeConstructorQuantity({ index, quantity })
                  }
                  onDelete={() => handleDeleteConstructor({ index })}
                  key={constructor.orderItemId}
                  isValidating={isPending}
                  product={constructor}
                  type='CONSTRUCTOR'
                />
              ))}
            </div>
            <FEButton
              className={style.submitButton}
              onClick={handleNavigate}
              showLoader={isPending}
              disabled={isPending || isCartEmpty}
            >
              <p className={clsx('headline', style.buttonLabel)}>
                {t('cart.ordering.price.total')}
              </p>
              <p
                className={clsx('headline', style.buttonPrice)}
              >{`${price?.total} ${currency}`}</p>
            </FEButton>
          </>
        )}
      </div>
    </>
  );
};

export const CartModal = ({ open, ...rest }: ModalProps & DataProps) => {
  const { mounted } = useMount({ isOpened: open });

  if (!mounted) {
    return null;
  }

  return (
    <FEPortal>
      <Layout {...rest} />
    </FEPortal>
  );
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

type DataProps = {
  buttonRef: RefObject<HTMLButtonElement>;
  dishes: DishInCartModel[];
  constructors: ConstructorInCartModel[];
  currency: CurrencySymbol;
  price: OrdersValidateResponseV4.OrderPrice | null;
  isPending: boolean;
};
