import { AuthClient } from 'api/base';
import clsx from 'clsx';
import { PROFILE_PATHNAME } from 'const';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';
import { useMediaQuery } from 'ui-kit/hooks';
import { Card } from './Card';
import { Empty } from './Empty';
import { useCards } from './lib/useCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import style from './SavedCards.module.css';

export const SavedCards = () => {
  const { t } = useTranslation();

  const is500 = useMediaQuery('(min-width: 500px)');
  const is1024 = useMediaQuery('(min-width: 1024px)');

  const navigate = useNavigate();

  const { cards } = useCards();

  const goBack = () => {
    const isDefault =
      ((window.history.state && window.history.state.key) || 'default') ===
      'default';

    if (isDefault) return navigate(`/${PROFILE_PATHNAME}`, { replace: true });
    return navigate(-1);
  };

  const isAuth = AuthClient.isAuth;

  return (
    <>
      {!isAuth && <Navigate to={'/?page=auth'} replace />}
      {isAuth && (
        <div className={style.savedCardsPage}>
          <div className={style.pageHeader}>
            <button
              onClick={goBack}
              className={clsx('button-arrowBack', style.backButton)}
            />
            <h2 className={is500 ? 'title' : 'headline'}>
              {t('profile.cardDetailsTitle')}
            </h2>
          </div>
          <div className={style.content}>
            {!!cards.length && (
              <div className={style.wrapper}>
                {is1024 &&
                  cards.map(({ id, type, lastFour, firstSix }) => (
                    <Card
                      firstSix={firstSix}
                      key={id}
                      lastFour={lastFour}
                      type={type}
                    />
                  ))}
                {!is1024 && (
                  <Swiper
                    initialSlide={0}
                    spaceBetween={12}
                    mousewheel={true}
                    modules={[Mousewheel]}
                    direction='horizontal'
                    slidesPerView={'auto'}
                  >
                    {cards.map(({ id, type, lastFour, firstSix }) => (
                      <SwiperSlide className={style.slide} key={id}>
                        <Card
                          lastFour={lastFour}
                          firstSix={firstSix}
                          type={type}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            )}
            {!cards.length && <Empty />}
          </div>
        </div>
      )}
    </>
  );
};
