import React from 'react';
import {IMG_IC_16_MARKER_TERTIARY} from 'images';
import { Block } from '..';

const Country = ({
  onClick,
  city,
  country,
}: {
  onClick: () => void;
  city: string | null;
  country: string | null;
}) => {
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (!country) setValue('Выбери страну');
    else if (!city) setValue(country);
    else setValue(`${country}, ${city}`);
  }, [city, country]);

  return <Block icon={IMG_IC_16_MARKER_TERTIARY} onClick={onClick} value={value} />;
};

export default Country;
