import { initCatalogFx } from 'domains/application';
import {
  $lastOrder,
  // getOrderLongPollFx
} from 'domains/cart';
import { subscribeLastActiveOrderFx } from 'domains/profile';
import { sample } from 'effector';
import { spread } from 'patronum';
import { getBannersByCatalogIdFx, initBannersFx } from './effects';
import { $banners } from './stores';

sample({
  clock: initCatalogFx.doneData,
  fn: ({ catalogId, currentCity }) => ({ catalogId, cityId: currentCity.id }),
  target: initBannersFx,
});

sample({
  clock: getBannersByCatalogIdFx.doneData,
  fn: ({ banners }) => banners,
  target: $banners,
});

sample({
  clock: getBannersByCatalogIdFx.failData,
  fn: () => [],
  target: $banners,
});

spread({
  source: sample({
    clock: initBannersFx.doneData,
    fn: ({ banners, lastOrder, orders }) => ({ banners, lastOrder, orders }),
  }),
  targets: {
    banners: $banners,
    lastOrder: $lastOrder,
  },
});

// initBannersFx.doneData.watch((res) => {
//   if (res.needToFetchLastOrder)
//     getOrderLongPollFx({ id: res.lastOrder!.order.id, timeout: 25 });
// });

sample({
  clock: initBannersFx.doneData,
  filter: (res) => res.needToFetchLastOrder,
  fn: (res) => ({ orderId: res.lastOrder!.order.id }),
  target: subscribeLastActiveOrderFx,
});
