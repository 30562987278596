import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'theme/base.css';
import 'theme/theme.css';
import 'theme/normalize.css';
import 'theme/colors.css';
import 'images/images.css';

import 'domains/init';

import '../app/styles/index.css';

import {
  DeliveryAndPaymentsPage,
  ContactsPage,
  Page404,
  StartPage,
  RestaurantsPage,
  CatalogDesktopPage,
  CatalogMobile,
} from 'pages';
import { GetParameterModals } from './routes';
import { ApplicationLoaderLayout } from 'layouts';
import {
  CONTACTS_PATHNAME,
  DELIVERY_AND_PAYMENTS_PATHNAME,
  PROFILE_PATHNAME,
  RESTAURANTS_PATHNAME,
  SAVED_CARDS_PATHNAME,
} from 'const';
import useMediaQuery from 'ui-kit/hooks/useMediaQuery';
import { ProfilePage } from 'pages/ProfilePage';
import { ApplicationLayout } from '../layouts';
import { SavedCards } from 'pages/SavedCards';

const App = () => {
  const isDesktop = useMediaQuery(`(min-width: ${1024}px)`);

  return (
    <>
      <BrowserRouter>
        <ApplicationLayout>
          {() => (
            <>
              <Routes>
                <Route path='/404' element={<Page404 />} />
                <Route path={`/${PROFILE_PATHNAME}`}>
                  <Route element={<ProfilePage />} index />
                  <Route path={SAVED_CARDS_PATHNAME} element={<SavedCards />} />
                </Route>
                <Route
                  path={`/${DELIVERY_AND_PAYMENTS_PATHNAME}`}
                  element={<DeliveryAndPaymentsPage />}
                />
                <Route
                  path={`/${CONTACTS_PATHNAME}`}
                  element={<ContactsPage />}
                />
                <Route
                  path={`/${RESTAURANTS_PATHNAME}`}
                  element={<RestaurantsPage />}
                />
                <Route
                  path='/*'
                  element={
                    isDesktop ? <CatalogDesktopPage /> : <CatalogMobile />
                  }
                ></Route>
              </Routes>
              <GetParameterModals />
              <StartPage />
              <ApplicationLoaderLayout />
            </>
          )}
        </ApplicationLayout>
      </BrowserRouter>
    </>
  );
};

export default App;
