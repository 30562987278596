import { useMatch, useNavigate } from 'react-router-dom';
import { useLinks } from 'hooks/router';
import {
  $address,
  $city,
  $currency,
  $deliveryZone,
  $restaurant,
  $type,
} from 'domains/cartography';
import style from './style.module.css';
import { useUnit } from 'effector-react';
import { $user } from 'domains/profile';
import {
  $constructors,
  $cutleries,
  $dishes,
  $isValidating,
  $lastOrder,
  $price,
  $promoCode,
  $promotionProduct,
  $timeTo,
  $urgent,
  changeDishQuantityFromCatalog,
} from 'domains/cart';
import {
  $catalogCategories,
  $catalogProducts,
  $stopLists,
} from 'domains/catalog';
import { BannersList } from './components';
import { MOBILE_MENU_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import {
  getConstructorAvailability,
  getDishAvailability,
  getDishesQuantityInCart,
  useCategoriesRoutes,
} from './model';
import { InView } from 'react-intersection-observer';
import { FEButton } from 'components';
import { $banners, $isPendingBanners } from 'domains/banners';
import Categories from './components/categories';
import React from 'react';
import { $giftRepurchases } from 'domains/giftRepurschases';
import { useTranslation } from 'react-i18next';
import { MetaContainer } from './components/metaContainer';
import CatalogCardFactory from 'pages/catalogDesktop/components/catalogCardFactory/catalogCardFactory';

const CatalogDesktopPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, address, restaurant] = useUnit([$type, $address, $restaurant]);
  const user = useUnit($user);
  const currency = useUnit($currency);

  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_MENU_WIDTH}px)`);

  const [products, categories] = useUnit([
    $catalogProducts,
    $catalogCategories,
  ]);

  const { activeCategoryId } = useCategoriesRoutes(categories);

  const stopLists = useUnit($stopLists);

  const [
    dishes,
    constructors,
    isValidating,
    cartPrice,
    cutleries,
    timeTo,
    urgent,
    promoCode,
    promotionProduct,
    deliveryZone,
    city,
    onChangeDishQuantityFromCatalog,
  ] = useUnit([
    $dishes,
    $constructors,
    $isValidating,
    $price,
    $cutleries,
    $timeTo,
    $urgent,
    $promoCode,
    $promotionProduct,
    $deliveryZone,
    $city,
    changeDishQuantityFromCatalog,
  ]);

  const [banners, isPendingBanners, lastOrder, giftRepurchases] = useUnit([
    $banners,
    $isPendingBanners,
    $lastOrder,
    $giftRepurchases,
  ]);

  const { cartLink } = useLinks();

  const match = useMatch('/:name');

  React.useEffect(() => {
    if (match) {
      const pathName = match.params.name;

      const currentCategoryFromUrl = categories.find(
        (category) => category.linkValue === pathName,
      );

      if (products) {
        const catalogProducts = [...products.dishes, ...products.constructors];
        if (
          !(
            pathName &&
            catalogProducts.some((product) => {
              const productPathname =
                // product.linkValue.counter === 1
                // ?
                product.linkValue.value;
              // : `${product.linkValue.value}${product.linkValue.counter}`
              return productPathname === pathName;
            })
          )
        ) {
          if (currentCategoryFromUrl) {
            if (activeCategoryId !== currentCategoryFromUrl.id) {
              const el = document.getElementById(currentCategoryFromUrl.id);
              el &&
                el.scrollIntoView({
                  behavior: 'auto',
                  block: 'start',
                });
            }
          }
        }
      } else {
        if (currentCategoryFromUrl) {
          if (activeCategoryId !== currentCategoryFromUrl.id) {
            const el = document.getElementById(currentCategoryFromUrl.id);
            el &&
              el.scrollIntoView({
                behavior: 'auto',
                block: 'start',
              });
          }
        }
      }
    }
  }, [categories, activeCategoryId, match, products]);

  const sortedProducts = React.useMemo(() => {
    return Object.fromEntries(
      categories.map((category) => [
        category.id,
        products?.constructors
          .filter((constructor) => constructor.categoryId === category.id)
          .map((constructor) => {
            return {
              ...constructor,
              type: 'constructor',
            };
          })
          .concat(
            //@ts-expect-error
            ...[
              products?.dishes
                .filter((dish) => dish.categoryId === category.id)
                .map((dish) => {
                  return {
                    ...dish,
                    type: 'dish',
                  };
                }),
            ],
          ),
      ]),
    );
  }, [categories, products?.constructors, products?.dishes]);

  const productsForBanners = React.useMemo(() => {
    const categoriesObject = Object.fromEntries(
      categories.map((category) => [category.id, category]),
    );

    return products
      ? [
          ...products.dishes.map((dish) => ({
            productId: dish.id,
            productName:
              // dish.linkValue.counter === 1
              //   ?
              dish.linkValue.value,
            // : `${dish.linkValue.value}${dish.linkValue.counter}`
            categoryId: dish.categoryId,
            categoryName: categoriesObject[dish.categoryId].name,
          })),
          ...products.constructors.map((constructor) => ({
            productId: constructor.id,
            productName: constructor.name,
            categoryId: constructor.categoryId,
            categoryName: categoriesObject[constructor.categoryId].name,
          })),
        ]
      : [];
  }, [categories, products]);

  return (
    <>
      <MetaContainer
        currency={currency}
        currentCity={city}
        categories={categories}
        products={products}
        deliveryZone={deliveryZone}
      />

      <div className={style.catalog}>
        <Categories
          activeCategoryId={activeCategoryId}
          currency={currency}
          categories={categories.map((category) => ({
            id: category.id,
            name: category.name,
            disabled: false,
            linkValue: category.linkValue,
          }))}
          isDesktop={isDesktop}
          dishes={dishes}
          constructors={constructors}
          isValidating={isValidating}
          price={cartPrice}
        />
        <div className={style.catalogContent}>
          <BannersList
            currency={currency}
            isPendingBanners={isPendingBanners}
            products={productsForBanners}
            giftRepurchases={giftRepurchases}
            banners={banners}
            lastOrder={lastOrder}
          />
          {categories.map((category, index) => {
            return products?.dishes.filter(
              (dish) => dish.categoryId === category.id,
            ).length !== 0 ||
              products?.constructors.filter(
                (constructor) => constructor.categoryId === category.id,
              ).length !== 0 ? (
              <InView
                key={index}
                rootMargin={isDesktop ? '-270px 0px 0px' : '-340px 0px 0px'}
              >
                {({ ref, inView }) => {
                  return (
                    <div
                      className={style.catalogCategory}
                      element-identificator='category'
                      category-name={category.linkValue}
                      category-id={category.id}
                      id={category.id}
                      active-status={inView ? 'active' : 'non-active'}
                      ref={ref}
                    >
                      <p className='title'>{category.name}</p>
                      <div className={style.categoryProducts}>
                        {sortedProducts[category.id]?.map((prod) => {
                          return (
                            <CatalogCardFactory
                              available={
                                prod.type === 'constructor'
                                  ? getConstructorAvailability(prod, stopLists)
                                  : getDishAvailability(
                                      //@ts-expect-error
                                      prod,
                                      stopLists,
                                    )
                              }
                              currency={currency}
                              key={prod.id}
                              className={style.catalogCard}
                              stopLists={stopLists}
                              product={prod}
                              validationPayload={{
                                deliveryZoneId: deliveryZone?.id || null,
                                request: {
                                  dishes,
                                  constructors,
                                  customer: user,
                                  cutleries: cutleries.map((cutlery) => ({
                                    orderItemId: cutlery.orderItemId,
                                    complectationId:
                                      cutlery.complectation.complectation.id,
                                    quantity: cutlery.quantity,
                                  })),
                                  timeTo,
                                  type,
                                  urgent,
                                  customerCount: 1,
                                  receivingAddress: address,
                                  receivingRestaurantId: restaurant?.id || null,
                                  promotionProduct,
                                  promoCode,
                                },
                              }}
                              videoClassName={
                                prod.type === 'dish' &&
                                prod.displayType === 'PROMOTION'
                                  ? style.videoCatalogCard
                                  : undefined
                              }
                              quantityInCart={getDishesQuantityInCart(
                                prod.id,
                                dishes,
                              )}
                              changeQuantityInCart={(quantity: any) =>
                                onChangeDishQuantityFromCatalog({
                                  productId: prod.id,
                                  quantity,
                                })
                              }
                              type={prod.type}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                }}
              </InView>
            ) : null;
          })}
        </div>
        {!isDesktop &&
          cartPrice &&
          (constructors.length !== 0 || dishes.length !== 0) && (
            <div className={style.cartButtonMobile}>
              <FEButton
                showLoader={isValidating}
                disabled={constructors.length === 0 && dishes.length === 0}
                onClick={() => navigate(cartLink)}
              >
                <div className={style.cartButtonMobile__button}>
                  <p className='headline'>{t('catalog.cart.label')}</p>
                  <p className='headline'>{`${cartPrice.total} ${currency}`}</p>
                </div>
              </FEButton>
            </div>
          )}
      </div>
    </>
  );
};

export default CatalogDesktopPage;
