import React, { useCallback } from 'react';
import FEHeaderProps from './props';
import { IMG_PLACEHOLDER_LOGO } from 'images';
import style from './style.module.css';
import { FEBar } from 'components';
import { Confirmation, Menu } from './components';
import { useTranslation } from 'react-i18next';
import { DesktopCartButton } from 'pages/catalogDesktop/components';

const FEHeader = ({
  orderType,
  address,
  restaurant,
  onAddressBarClick,
  onLogoClick,
  onCloseConfirmation,
  onDeclineConfirmation,
  isOpenConfirmation,
}: FEHeaderProps) => {
  const { t } = useTranslation();
  const [logoWidth, setLogoWidth] = React.useState<number>(0);

  const logoRefCallback = useCallback((node: HTMLElement | null) => {
    if (node) {
      const width = node.getBoundingClientRect().width;
      setLogoWidth(width);
    } else {
      setLogoWidth(0);
    }
  }, []);

  return (
    <div className={style.header}>
      <div className={style.container}>
        <div className={style.appData}>
          <img
            ref={logoRefCallback}
            className={style.logo}
            src={IMG_PLACEHOLDER_LOGO}
            alt=''
            onClick={onLogoClick}
          />
          <div
            className={style.addressContainer}
            style={{
              width: `calc(100% - ${logoWidth}px)`,
            }}
          >
            <FEBar
              onClick={onAddressBarClick}
              label={
                orderType === 'COURIER'
                  ? t('cartography.header.deliveryLabel')
                  : t('cartography.header.pickupLabel')
              }
              value={orderType === 'COURIER' ? address : restaurant}
              placeholder={t('cartography.header.placeholder')}
            />
            <Confirmation
              value={orderType === 'COURIER' ? address : restaurant}
              open={isOpenConfirmation}
              onClose={onCloseConfirmation}
              onDeclineConfirmation={onDeclineConfirmation}
            />
          </div>
        </div>
        <div className={style.headerButtonsContainer}>
          <div className={style.userData}>
            <Menu />
          </div>
          <DesktopCartButton />
        </div>
      </div>
    </div>
  );
};

export default FEHeader;
export type { FEHeaderProps };
