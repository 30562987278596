import {
  GetCatalogCategoriesResponseV1,
  GetDeliveryZoneStopListResponseV1,
} from 'api/version1';
import { GetCatalogProductsResponseV3 } from 'api/version3';
import { DishInCartModel } from 'domains/cart';
import { useCallback, useEffect, useRef, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router';

export const getDishesQuantityInCart = (
  productId: string,
  dishes: DishInCartModel[],
) => {
  const quantity = dishes
    .filter((dish) => dish.product.product.id === productId)
    .reduce((prev, dish) => prev + dish.product.quantity, 0);
  return quantity !== 0 ? quantity : null;
};

export const getDishAvailability = (
  { bases }: GetCatalogProductsResponseV3.Dish,
  stopLists: GetDeliveryZoneStopListResponseV1 | null,
) => {
  if (stopLists === null) return true;
  return !bases
    .map((base) => base.id)
    .every((baseId) => stopLists.bases.includes(baseId));
};

export const getConstructorAvailability = (
  { bases }: GetCatalogProductsResponseV3.Constructor,
  stopLists: GetDeliveryZoneStopListResponseV1 | null,
) => {
  if (stopLists === null) return true;
  return bases
    .map((base) => base.id)
    .every((baseId) => !stopLists.bases.includes(baseId));
};

type CategoriesByLink = {
  [linkValue: string]: GetCatalogCategoriesResponseV1.ResponseItem;
};

export const useCategoriesRoutes = (
  categories: GetCatalogCategoriesResponseV1.ResponseItem[],
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firstRenderRef = useRef(true);
  const [activeCategoryId, setActiveCategoryId] = useState('');
  const [categoriesByLinkValue, setCategoriesByLinkValue] =
    useState<null | CategoriesByLink>(null);

  const matches = matchRoutes(
    [
      {
        path: '/:categoryLinkValue',
      },
      {
        path: '/:categoryLinkValue/promotions',
      },
      {
        path: '/:categoryLinkValue/legal-information',
      },
    ],
    location,
  );

  const handleInit = useCallback(
    (categories: CategoriesByLink) => {
      const matchLink = matches?.[0].params.categoryLinkValue;
      if (matchLink && matchLink in categories) {
        const { id } = categories[matchLink];
        setActiveCategoryId(id);
        const categoryEl = document.querySelector(`div[category-id = '${id}']`);
        if (categoryEl) {
          categoryEl.setAttribute('active-status', 'active');
          categoryEl.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
      }
    },
    [matches],
  );

  const handleChangeIntersectingCategory = useCallback(
    () => {
      const els = document.querySelectorAll(
        `div[element-identificator = 'category'][active-status = 'active']`,
      );
      if (els.length) {
        const el = els[0];
        if (el) {
          const location = window.location;
          const elCategoryId = el.getAttribute('category-id');
          const elCategoryName = el.getAttribute('category-name');
          setActiveCategoryId(elCategoryId!);
          location.pathname !== `/${elCategoryName}` &&
            navigate(
              elCategoryName ? `/${elCategoryName}${location.search}` : '',
              {
                replace: true,
              },
            );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', () => handleChangeIntersectingCategory());
    return () => {
      window.removeEventListener('scroll', () =>
        handleChangeIntersectingCategory(),
      );
    };
  }, [handleChangeIntersectingCategory]);

  useEffect(() => {
    if (categoriesByLinkValue && firstRenderRef.current) {
      handleInit(categoriesByLinkValue);
      firstRenderRef.current = false;
    }
  }, [categoriesByLinkValue, handleInit]);

  useEffect(() => {
    const categoriesByLinkValue: CategoriesByLink = {};
    for (let category of categories) {
      const linkValue = category.linkValue;
      categoriesByLinkValue[linkValue] = category;
    }
    if (Object.keys(categoriesByLinkValue).length)
      setCategoriesByLinkValue(categoriesByLinkValue);
  }, [categories]);

  useEffect(() => {
    if (!activeCategoryId) {
      setActiveCategoryId(categories[0]?.id);      
    }
  }, [categories]);

  return {
    activeCategoryId,
  };
};
