import React from 'react';
import clsx from 'clsx';
import { FEButton } from '..';
import FEQuantityProps from './props';
import style from './style.module.css';
import {
  IMG_IC_24_PLUS,
  IMG_IC_24_MINUS,
  IMG_IC_24_MINUS_ON_COLOR,
  IMG_IC_24_PLUS_ON_COLOR,
} from 'images';

const FEQuantity = ({
  value,
  onChange,
  max,
  min,
  fill = 'default',
  disabled = false,
  size = 'medium',
  valueColor = 'primary',
  disabledMin = false,
  disabledMax = false,
  variant
}: FEQuantityProps) => (
  <div className={clsx(style.root, fill === 'none' && style.root__noFill, variant === 'catalog' && style.root__catalogVariant)}>
    <FEButton
      disabled={value === min || disabled || disabledMin}
      className={clsx(
        style.button,
        fill === 'none' && style.button__noFill,
        size === 'large' && style.button__large
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (min !== undefined) value !== min && onChange(value - 1);
        else {
          onChange(value - 1);
        }
      }}
    >
      <img
        className={clsx('icon-24', style.image)}
        src={variant === 'catalog' ? IMG_IC_24_MINUS_ON_COLOR : fill === 'none' ? IMG_IC_24_MINUS : IMG_IC_24_MINUS_ON_COLOR}
        alt=''
      />
    </FEButton>
    <p
      className={clsx(
        'subhead',
        style.quantity,
        fill === 'none' && style.quantity__noFill,
        valueColor === 'onColor' && style.onColor,
      )}
    >
      {value}
    </p>
    <FEButton
      disabled={value === max || disabled || disabledMax}
      className={clsx(
        style.button,
        fill === 'none' && style.button__noFill,
        size === 'large' && style.button__large,
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (max !== undefined) value !== max && onChange(value + 1);
        else {
          onChange(value + 1);
        }
      }}
    >
      <img
        className={clsx('icon-24', style.image)}
        src={variant === 'catalog' ? IMG_IC_24_PLUS_ON_COLOR : fill === 'none' ? IMG_IC_24_PLUS : IMG_IC_24_PLUS_ON_COLOR}
        alt=''
      />
    </FEButton>
  </div>
);

export default FEQuantity;
export type { FEQuantityProps };
