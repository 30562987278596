import FeatSDKV1, {
  GetCatalogCategoriesResponseV1,
  GetDeliveryZoneStopListResponseV1,
} from 'api/version1';
import FeatSDKV2 from 'api/version2';
import FeatSDKV3, { GetCatalogProductsResponseV3 } from 'api/version3';
import {
  City,
  Country,
  DeliveryZone,
  DeliveryZoneAndMapLayerZone,
  Restaurant,
  TimeIntervals,
} from 'domains/cartography';
import { createEffect } from 'effector';
import { Address, PaymentType, OrderType } from 'models';
import { isInSchedule } from 'components';
import { FELocalStorageUtil } from 'utils';
import { FeatureModel } from 'domains/features';
import { GET_CURRENCY_SYMBOL } from 'const';
import { SitesMap } from 'domains/sitesMap';
const punycode = require('punycode');

export const initCodeFx = createEffect(
  async (code: string) => await FeatSDKV1.code.getCode(code),
);

export const initSignFx = createEffect(FeatSDKV1.sign.getSign);

export const initCatalogFx = createEffect(
  async (showConfirmation?: boolean) => {
    const host = window.location.host || null;

    const countries = (await FeatSDKV1.countries.getCountries()).items;
    const allCities = (await FeatSDKV1.cities.getCities()).items;
    let cities: City[];
    let currentCountry: Country;
    let currentCity: City;
    let subdomainCity: City | null = null;
    const getSubdomainCity = (host: string) =>
      allCities.find(
        (city) => city.code === punycode.toUnicode(host.split('.')[0]),
      );

    if (host) subdomainCity = getSubdomainCity(host) || null;

    let restaurants: Restaurant[];

    let currentAddress: Address.ReceivingAddressV2 | null = null;
    let currentRestaurant: Restaurant | null = null;
    let currentType: OrderType = 'COURIER';

    let catalogId: string;
    let catalogProducts: GetCatalogProductsResponseV3.Response;
    let catalogCategories: GetCatalogCategoriesResponseV1.ResponseItem[];

    let deliveryZone: DeliveryZone | null = null;
    let deliveryZonesAndMapLayerZones: DeliveryZoneAndMapLayerZone[] = [];

    let timeIntervals: TimeIntervals;
    let stopLists: GetDeliveryZoneStopListResponseV1 | null;
    let paymentTypes: PaymentType[];

    let useLocalStorageData = false;

    const {
      type: localStorageType,
      address: localStorageAddress,
      restaurant: localStorageRestaurant,
    } = FELocalStorageUtil.getCartography;

    if (subdomainCity) {
      if (
        subdomainCity!.id ===
        (localStorageAddress
          ? localStorageAddress!.cityId
          : localStorageRestaurant
          ? localStorageRestaurant!.cityId
          : '')
      ) {
        currentCountry = countries.find(
          (country) => country.id === subdomainCity!.countryId,
        )!;
        cities = allCities.filter(
          (city) => city.countryId === currentCountry.id,
        );
        currentCity = subdomainCity!;
        currentType = localStorageType || 'COURIER';
        useLocalStorageData = true;
      } else {
        currentCountry = countries.find(
          (country) => country.id === subdomainCity!.countryId,
        )!;
        cities = allCities.filter(
          (city) => city.countryId === currentCountry.id,
        );
        currentCity = subdomainCity!;
      }
    } else {
      if (localStorageAddress || localStorageRestaurant) {
        const localStorageCity = allCities.find(
          (city) =>
            city.id ===
            (localStorageAddress
              ? localStorageAddress!.cityId
              : localStorageRestaurant!.cityId),
        );
        if (localStorageCity) {
          currentCountry = countries.find(
            (country) => country.id === localStorageCity.countryId,
          )!;
          cities = allCities.filter(
            (city) => city.countryId === currentCountry.id,
          );
          currentCity = localStorageCity;
          currentType = localStorageType || 'COURIER';
          useLocalStorageData = true;
        } else {
          currentCountry =
            countries.find((country) => country.default) || countries[0];
          cities = allCities.filter(
            (city) => city.countryId === currentCountry.id,
          );
          currentCity = cities.find((city) => city.default) || cities[0];
        }
      } else {
        currentCountry =
          countries.find((country) => country.default) || countries[0];
        cities = allCities.filter(
          (city) => city.countryId === currentCountry.id,
        );
        currentCity = cities.find((city) => city.default) || cities[0];
      }
    }

    if (useLocalStorageData) {
      if (localStorageAddress) {
        currentAddress = localStorageAddress!;
        const cityId = currentCity.id;
        const { latitude, longitude } = currentAddress.location;

        try {
          restaurants = (
            await FeatSDKV1.restaurants.getRestaurantsByCityId({ cityId })
          ).items;
        } catch (error) {
          restaurants = [];
        }

        try {
          deliveryZone =
            await FeatSDKV1.deliveryZones.getDeliveryZoneByGeolocation({
              cityId,
              geolocation: {
                latitude,
                longitude,
              },
            });

          if (deliveryZone.status === 'OPEN') {
            paymentTypes = deliveryZone.availablePaymentTypes;
            catalogId = deliveryZone.catalogId;

            timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
              deliveryZone.id,
            );

            try {
              stopLists = await FeatSDKV1.deliveryZones.getDeliveryZoneStopList(
                {
                  id: deliveryZone.id,
                },
              );
            } catch (error) {
              stopLists = null;
            }

            catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
              catalogId,
              cityId,
              request: { limit: 999, offset: 0 },
            });

            catalogCategories = (
              await FeatSDKV1.catalog.getCatalogCategories({
                catalogId,
              })
            ).items;
          } else {
            const cityId = currentCity.id;

            try {
              restaurants = (
                await FeatSDKV1.restaurants.getRestaurantsByCityId({ cityId })
              ).items;
            } catch (error) {
              restaurants = [];
            }
            let currentCityRestaurant = restaurants.find(
              (restaurant) => restaurant.id === currentCity.defaultRestaurantId,
            );
            if (currentCityRestaurant === undefined) {
              currentCityRestaurant =
                await FeatSDKV1.restaurants.getRestaurantById({
                  id: currentCity.defaultRestaurantId!,
                });
            }

            catalogId = currentCityRestaurant.catalogId;

            paymentTypes = currentCityRestaurant.availablePaymentTypes;

            catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
              catalogId,
              cityId,
              request: { limit: 999, offset: 0 },
            });
            catalogCategories = (
              await FeatSDKV1.catalog.getCatalogCategories({
                catalogId,
              })
            ).items;

            timeIntervals = await FeatSDKV2.deliveryZone.getTimeIntervals(
              deliveryZone.id,
            );

            try {
              stopLists = await FeatSDKV1.deliveryZones.getDeliveryZoneStopList(
                {
                  id: deliveryZone.id,
                },
              );
            } catch (error) {
              stopLists = null;
            }
          }
        } catch (error) {
          currentAddress = null;
          useLocalStorageData = false;
          const cityId = currentCity.id;

          try {
            restaurants = (
              await FeatSDKV1.restaurants.getRestaurantsByCityId({
                cityId: currentCity.id,
              })
            ).items;
          } catch (error) {
            restaurants = [];
          }
          let currentCityRestaurant = restaurants.find(
            (restaurant) => restaurant.id === currentCity.defaultRestaurantId,
          );
          if (currentCityRestaurant === undefined) {
            currentCityRestaurant =
              await FeatSDKV1.restaurants.getRestaurantById({
                id: currentCity.defaultRestaurantId!,
              });
          }
          catalogId = currentCityRestaurant.catalogId;

          paymentTypes = currentCityRestaurant.availablePaymentTypes;

          catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
            catalogId,
            cityId,
            request: { limit: 999, offset: 0 },
          });
          catalogCategories = (
            await FeatSDKV1.catalog.getCatalogCategories({
              catalogId,
            })
          ).items;

          timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
            currentCityRestaurant.id,
          );

          try {
            stopLists = await FeatSDKV1.restaurants.getRestaurantStopList({
              id: currentCityRestaurant.id,
            });
          } catch (error) {
            stopLists = null;
          }
        }
      } else {
        currentRestaurant = await FeatSDKV1.restaurants.getRestaurantById({
          id: localStorageRestaurant!.id,
        });
        const cityId = currentCity.id;

        catalogId = currentRestaurant.catalogId;
        paymentTypes = currentRestaurant.availablePaymentTypes;

        try {
          restaurants = (
            await FeatSDKV1.restaurants.getRestaurantsByCityId({
              cityId: currentCity.id,
            })
          ).items;
        } catch (error) {
          restaurants = [];
        }

        catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
          catalogId,
          cityId,
          request: { limit: 999, offset: 0 },
        });

        catalogCategories = (
          await FeatSDKV1.catalog.getCatalogCategories({
            catalogId,
          })
        ).items;

        timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
          currentRestaurant.id,
        );

        try {
          stopLists = await FeatSDKV1.restaurants.getRestaurantStopList({
            id: currentRestaurant.id,
          });
        } catch (error) {
          stopLists = null;
        }
      }
    } else {
      const cityId = currentCity.id;

      try {
        restaurants = (
          await FeatSDKV1.restaurants.getRestaurantsByCityId({
            cityId: currentCity.id,
          })
        ).items;
      } catch (error) {
        restaurants = [];
      }

      let currentCityRestaurant = restaurants.find(
        (restaurant) => restaurant.id === currentCity.defaultRestaurantId,
      );
      if (currentCityRestaurant === undefined) {
        currentCityRestaurant = await FeatSDKV1.restaurants.getRestaurantById({
          id: currentCity.defaultRestaurantId!,
        });
      }
      catalogId = currentCityRestaurant.catalogId;

      paymentTypes = currentCityRestaurant.availablePaymentTypes;

      catalogProducts = await FeatSDKV3.catalog.getCatalogProducts({
        catalogId,
        cityId,
        request: { limit: 999, offset: 0 },
      });
      catalogCategories = (
        await FeatSDKV1.catalog.getCatalogCategories({
          catalogId,
        })
      ).items;

      timeIntervals = await FeatSDKV2.restaurants.getTimeIntervals(
        currentCityRestaurant.id,
      );

      try {
        stopLists = await FeatSDKV1.restaurants.getRestaurantStopList({
          id: currentCityRestaurant.id,
        });
      } catch (error) {
        stopLists = null;
      }
    }

    try {
      deliveryZonesAndMapLayerZones =
        await FeatSDKV1.deliveryZones.getDeliveryZoneAndMapLayerZoneByCityId({
          cityId: currentCity.id,
        });
    } catch (error) {
      deliveryZonesAndMapLayerZones = [];
    }

    const cartData = FELocalStorageUtil.getCartData;

    let timeTo;
    let urgent;

    const schedule = currentRestaurant
      ? {
          openAt: currentRestaurant.openAt,
          closeAt: currentRestaurant.closeAt,
        }
      : deliveryZone
      ? {
          openAt: deliveryZone.openAt,
          closeAt: deliveryZone.closeAt,
        }
      : null;

    if (schedule) {
      if (
        isInSchedule(
          {
            timeInterval: timeIntervals.timeVariants[0],
            schedule,
          },
          true,
        )
      ) {
        timeTo = null;
        urgent = true;
      } else {
        timeTo = timeIntervals.timeVariants[0];
        urgent = null;
      }
    } else {
      timeTo = null;
      urgent = true;
    }

    let features: FeatureModel[];

    try {
      features = (
        await FeatSDKV1.features.getFeaturesByPlaceId({
          placeId: currentRestaurant
            ? currentRestaurant.id
            : deliveryZone
            ? deliveryZone.id
            : '',
        })
      ).items;
    } catch (error) {
      features = [];
    }

    let sitesMap: SitesMap | null;
    try {
      sitesMap = await FeatSDKV1.sitesMap.getSitesMapByCountryId({
        countryId: currentCountry.id,
      });
    } catch (error) {
      sitesMap = null;
    }

    const currency = GET_CURRENCY_SYMBOL[currentCountry.currency];

    return {
      sitesMap,
      features,
      countries,
      currentCountry,
      currency,
      allCities,
      cities,
      currentCity,

      currentAddress,
      currentRestaurant,
      type: currentType,

      catalogId,
      catalogProducts,
      catalogCategories,
      deliveryZone,
      timeIntervals,
      stopLists,
      paymentTypes,
      paymentType:
        paymentTypes.filter(
          (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
        ).length === 1
          ? paymentTypes.filter(
              (paymentType) =>
                paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
            )[0]
          : null,
      restaurants,

      deliveryZonesAndMapLayerZones,

      urgent,
      timeTo,

      useLocalStorageData: useLocalStorageData,
      showAddressConfirmation: showConfirmation !== false,
      cartData,
    };
  },
);
