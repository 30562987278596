import { GET_PARAMS, GET_ENUMS } from 'const';
import { usePrepareLink } from 'hooks/router';

const useLinks = () => {
  const cartographyLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
  });

  const authLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.auth,
    },
  });

  const editProfileLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.editProfile,
    },
  });

  const cartLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cart,
    },
  });

  const ordersLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.orders,
    },
  });

  const bonusLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.bonus,
    },
  });

  return {
    cartographyLink,
    authLink,
    editProfileLink,
    cartLink,
    ordersLink,
    bonusLink,
  };
};

export default useLinks;
