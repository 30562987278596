import { $deliveryZone, $restaurant } from 'domains/cartography';
import { createEffect, sample } from 'effector';
import { savedCardsApi } from '../api/savedCardsApi';
import { getSavedCardsByPlaceIdFx } from './getSavedCardsByPlaceIdFx';
import { getSavedCardsFx } from './getSavedCardsFx';

export const deleteSavedCardFx = createEffect(savedCardsApi.deleteSavedCard);

sample({
  clock: deleteSavedCardFx.doneData,
  target: getSavedCardsFx,
});

sample({
  clock: deleteSavedCardFx.doneData,
  source: {
    $restaurant,
    $deliveryZone,
  },
  fn: ({ $restaurant: restaurant, $deliveryZone: deliveryZone }) => {
    if (restaurant) {
      return restaurant.id;
    }

    return deliveryZone!.id;
  },
  target: getSavedCardsByPlaceIdFx,
});
