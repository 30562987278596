import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PlaceholderLogo from 'images/img/placeholder/placeholder_icon_card.svg';
import style from './BonusGroup.module.css';

export const BonusGroup = ({ balance }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <p className={clsx('subhead', style.title)}>{t('profile.bonus')}</p>
      <span className={clsx('subhead', style.balance)}>{balance}</span>
      {/* TODO: Сменить лого на иконку из либы */}
      <img className={style.logo} src={PlaceholderLogo} alt='' />
    </div>
  );
};

type Props = {
  balance: number;
};
