import clsx from 'clsx';
import { SavedCard } from 'domains/savedCards';
import style from './Card.module.css';
import AMERICAN_EXPRESS from 'images/ic/24/american_express.svg';
import JCB from 'images/ic/24/jcb.svg';
import MASTERCARD from 'images/ic/24/mastercard.svg';
import UNION_PAY from 'images/ic/24/unionpay.svg';
import MIR from 'images/ic/24/mir.svg';
import VISA from 'images/ic/24/visa.svg';

const GET_CARD_TYPE_ICON: Record<SavedCard['type'], string> = {
  AMERICAN_EXPRESS: AMERICAN_EXPRESS,
  JCB: JCB,
  MASTERCARD: MASTERCARD,
  UNION_PAY: UNION_PAY,
  MIR: MIR,
  VISA: VISA,
  OTHER: '',
};

export const Card = ({ lastFour, type, className }: Props) => {
  return (
    <div className={clsx(style.card, className)}>
      <img className={style.typeIcon} src={GET_CARD_TYPE_ICON[type]} alt='' />
      <p className={clsx('text-1', style.mask)}>{`**** ${lastFour}`}</p>
    </div>
  );
};

type Props = Pick<SavedCard, 'lastFour' | 'type'> & { className: string };
