import { Banner } from 'domains/banners';
import style from './style.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './navigation.css';
import { Navigation, Mousewheel } from 'swiper';
import { LastOrder } from 'domains/cart';
import {
  Banner as BannerComponent,
  GiftRepurchaseBanner,
  SkeletonBanners,
} from './components';
import clsx from 'clsx';
import { GiftRepurschase } from 'domains/giftRepurschases';
import { CurrencySymbol } from 'models';
import {useEffect, useRef, useState} from "react";

const BannersList = ({
  banners,
  giftRepurchases,
  lastOrder,
  products,
  isPendingBanners,
  currency,
}: {
  currency: CurrencySymbol;
  banners: Banner[];
  lastOrder: LastOrder | null;
  giftRepurchases: GiftRepurschase[];
  products: {
    productId: string;
    productName: string;
    categoryId: string;
    categoryName: string;
  }[];
  isPendingBanners: boolean;
}) => {
  const idGropuedProducts = Object.fromEntries(
    products.map((product) => [product.productId, product]),
  );
  const [topOffset, setTopOffset] = useState(336)

  const bannerRef = useRef<any>();

  useEffect(() => {
    setTopOffset(bannerRef.current?.clientHeight)
    function setTop() {
      setTopOffset(bannerRef.current?.clientHeight)
    }
    window.addEventListener('resize', setTop)
    return () => {
      window.removeEventListener('resize', setTop);
    };
  }, [bannerRef.current?.clientHeight]);
  
  return (
    <div className={style.banners} style={{top: `calc(-${topOffset}px + 116px)`}} ref={bannerRef}>
      {!isPendingBanners ? (
        <Swiper
          initialSlide={0}
          spaceBetween={12}
          navigation={false}
          mousewheel={true}
          modules={[Navigation, Mousewheel]}
          direction='horizontal'
          className={style.list}
          slidesPerView={"auto"}
        >
          {/* {giftRepurchases.map((giftRepurchase, i) => (
            <SwiperSlide
              key={giftRepurchase.id}
              className={clsx(style.banner, style.banner__giftRepurchase)}
            >
              <GiftRepurchaseBanner
                {...{ giftRepurchase, currency, products: idGropuedProducts }}
              />
            </SwiperSlide>
          ))} */}
          {banners.map((banner, i) => (
            <SwiperSlide
              key={banner.id}
              className={clsx(style.banner, style.banner__story)}
            >
              <BannerComponent products={idGropuedProducts} banner={banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <SkeletonBanners showLastOrderBanner={false} />
      )}
    </div>
  );
};

export default BannersList;
