import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthClient } from 'api/base';
import clsx from 'clsx';
import style from './ProfilePage.module.css';
import { CustomerCard } from './components/CustomerCard';
import { NavigateButton } from './components/NavigateButton';
import { useLinks } from 'hooks/router';
import { SavedCardsCard } from './components/SavedCardsCard';
import { useState } from 'react';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEButton } from 'ui-kit/components';
import { useUnit } from 'effector-react';
import { logOutUser } from 'domains/profile';

export const ProfilePage = () => {
  const { t } = useTranslation();

  const is1024 = useMediaQuery('(min-width: 1024px)');

  const is500 = useMediaQuery('(min-width: 500px)');

  const [withCards, setWithCards] = useState(false);

  const navigate = useNavigate();

  const goBack = () => {
    const isDefault =
      ((window.history.state && window.history.state.key) || 'default') ===
      'default';

    if (isDefault) return navigate('/', { replace: true });
    return navigate(-1);
  };

  const { ordersLink, bonusLink } = useLinks();

  const handleLogOut = useUnit(logOutUser);

  const isAuth = AuthClient.isAuth;

  return (
    <>
      {!isAuth && <Navigate to={'/?page=auth'} replace />}
      {isAuth && (
        <div className={style.profilePage}>
          <div className={style.pageHeader}>
            <button
              onClick={goBack}
              className={clsx('button-arrowBack', style.backButton)}
            />
            <h2 className={is500 ? 'title' : 'headline'}>{t('Профиль')}</h2>
          </div>

          <div className={style.contentGrid}>
            <CustomerCard />

            <div className={style.secondaryGrid}>
              <SavedCardsCard
                onChangeWithCards={setWithCards}
                className={clsx(style.pageItemContainer, style.savedCards)}
              />
              <div
                with-cards={withCards.toString()}
                onClick={() => navigate(ordersLink)}
                className={clsx(
                  style.pageItemContainer,
                  style.pageItemContainerDisplay,
                  style.orders,
                )}
              >
                <p className={is500 ? 'title' : 'headline'}>
                  {t('profile.myOrders')}
                </p>
                <NavigateButton
                  className={style.navigateButton}
                  onClick={() => navigate(ordersLink)}
                />
              </div>
              <div
                with-cards={withCards.toString()}
                onClick={() => navigate(bonusLink)}
                className={clsx(
                  style.pageItemContainer,
                  style.pageItemContainerDisplay,
                  style.bonusHistory,
                )}
              >
                <p className={is500 ? 'title' : 'headline'}>
                  {t('profile.bonusHistory')}
                </p>
                <NavigateButton
                  className={style.navigateButton}
                  onClick={() => navigate(bonusLink)}
                />
              </div>
            </div>

            {!is1024 && (
              <div className={style.logOutButtonContainer}>
                <FEButton onClick={handleLogOut} type='secondary'>
                  {t('Выйти')}
                </FEButton>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
