import { $features } from 'domains/features';
import { $bonusAccount, $user } from 'domains/profile';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { FEHeadersCookiesUtil } from 'utils';

const formatPhone = (phone: string, mask: string) => {
  const splittedMask = mask.replace(/[+[\]()]/g, '').split(' ');

  const pattern = new RegExp(
    splittedMask
      .map(
        (v, i) =>
          `(${i === 0 ? '^' : ''}\\d{${v.length}}${
            i === splittedMask.length - 1 ? '$' : ''
          })`,
      )
      .join(''),
  );

  let count = 0;

  const replacer = mask
    .replace(/[[\]]/g, '')
    .replace(/[0-9]+/g, () => `$${++count}`);

  return phone.replace(pattern, replacer);
};

export const useData = () => {
  const [phone, setPhone] = useState('');

  const [customer, features, bonusAccount] = useUnit([
    $user,
    $features,
    $bonusAccount,
  ]);

  useEffect(() => {
    const mask = FEHeadersCookiesUtil.getPhoneMask;
    if (customer?.phone && mask) {
      setPhone(formatPhone(customer.phone, mask));
    }
  }, [customer?.phone]);

  return {
    phone: phone,
    name: customer?.fullName?.firstName ?? null,
    isLoyaltyProgramAvailable: features.includes('LOYALTY_PROGRAM'),
    bonusAccountBalance: bonusAccount?.balance ?? null,
  };
};
