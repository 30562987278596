// import FeatSDKV3, { GetAllOrdersResponseV3 } from 'api/version3';
import FeatSDKV1, { UpdateCustomerRequestV1 } from 'api/version1';
import { createEffect } from 'effector';
// import { LastOrder } from 'domains/cart';
// import { FELocalStorageUtil } from 'utils';
import { BonusAccount, BonusAccountTrasaction, User } from './entities';
import { AuthClient } from 'api/base';
// import { pending } from 'patronum';
// import { abortWrapper } from './model';

export const getSmsCodeFx = createEffect(FeatSDKV1.auth.start);

export const confirmCodefx = createEffect(FeatSDKV1.auth.confirm);

export const refreshTokenFx = createEffect(FeatSDKV1.auth.refresh);

export const getUserProfileFx = createEffect(FeatSDKV1.customer.getCustomer);

export const updateUserProfileFx = createEffect(
  async ({
    image,
    sex,
    birthday,
    firstName,
    lastName,
    email,
  }: Partial<Omit<UpdateCustomerRequestV1, 'fullName'>> & {
    image?: File;
    firstName?: string | null;
    lastName?: string | null;
  }) => {
    if (sex || birthday || firstName || lastName || email) {
      await FeatSDKV1.customer.updateCustomer({
        sex: sex || null,
        fullName: {
          firstName: firstName || null,
          lastName: lastName || null,
          middleName: null,
        },
        birthday: birthday || null,
        email: email || null,
      });
    }
    if (image) {
      const { id } = await FeatSDKV1.images.postImage({ file: image });
      await FeatSDKV1.customer.updateAvatar({ avatarId: id });
    }

    const user = await FeatSDKV1.customer.getCustomer();

    return user;
  },
);
// export const updateUserProfileFx = createEffect(
//   FeatSDKV1.customer.updateCustomer,
// );

export const updateUserAvatarFx = createEffect(FeatSDKV1.customer.updateAvatar);

export const deleteUserFx = createEffect(FeatSDKV1.customer.deleteCustomer);

export const getBonusAccountFx = createEffect(
  FeatSDKV1.bonusAccounts.getBonusAccounts,
);

export const getBonusAccountTransactionsFx = createEffect(
  FeatSDKV1.bonusAccounts.getBonusAccountTransactions,
);

export const initUserFx = createEffect(async () => {
  let customer: User | null = null;
  let bonusAccount: BonusAccount | null = null;
  let bonusAccountTransactions: BonusAccountTrasaction[] = [];

  if (AuthClient.isAuth) {
    const userProfile = await FeatSDKV1.customer.getCustomer();
    customer = {
      header: userProfile.header || null,
      fullName: userProfile.fullName || null,
      avatarId: userProfile.avatarId || null,
      phone: userProfile.phone,
      sex: userProfile.sex,
      birthday: userProfile.birthday || null,
      birthdayModerate: userProfile.birthdayModerate || null,
      cityId: userProfile.cityId,
      email: userProfile.email || null,
      socialAccounts: userProfile.socialAccounts || [],
    };
    bonusAccount = (await FeatSDKV1.bonusAccounts.getBonusAccounts()).items[0];
    if (bonusAccount) {
      bonusAccountTransactions = (
        await FeatSDKV1.bonusAccounts.getBonusAccountTransactions({
          id: bonusAccount.id,
        })
      ).items;
    }
  }

  return { customer, bonusAccount, bonusAccountTransactions };
});

// export const getOrdersWithParamsFx = createEffect<
//   { limit: number; offset: number },
//   GetAllOrdersResponseV3.ResponseItem[]
// >(
//   abortWrapper(async ({ offset, limit }, signal) => {
//     return (await FeatSDKV3.orders.getAllOrders({ limit, offset }, signal))
//       .items;
//   }),
// );

// export const $pendingGetOrders = pending({ effects: [getOrdersWithParamsFx] });
