import { RefObject, useCallback, useEffect, useInsertionEffect, useRef } from 'react';

type ElementTarget = RefObject<HTMLElement | null> | HTMLElement | null;

type Callback = (elementRect: DOMRect | null) => void;

const getElement = <Target extends ElementTarget>(target: Target) => {
  if (target instanceof HTMLElement) {
    return target;
  }

  if (target?.current) {
    return target.current;
  }

  return null;
};

const checkIfChanged = (prevRect: DOMRect, nextRect: DOMRect) => {
  let hasChanges = false;

  for (const key in prevRect) {
    if (nextRect[key as keyof DOMRect] !== prevRect[key as keyof DOMRect]) {
      hasChanges = true;
    }
  }
  return hasChanges;
};

export const useRectObserver = (targetElement: ElementTarget, callback: Callback) => {
  const internalCallback = useRef(callback);

  const prevRect = useRef<DOMRect>();

  const requestAnimationFrameId = useRef<number>();

  useInsertionEffect(() => {
    internalCallback.current = callback;
  }, [callback]);

  const run = useCallback(() => {
    const target = getElement(targetElement);
    const currentRect = target?.getBoundingClientRect();

    if (!currentRect) {
      requestAnimationFrameId.current = requestAnimationFrame(run);
      return;
    }

    if (!prevRect.current) {
      internalCallback.current(currentRect);
      prevRect.current = currentRect;
    } else {
      const didRectChanged = checkIfChanged(prevRect.current, currentRect);

      if (didRectChanged) {
        internalCallback.current(currentRect);
        prevRect.current = currentRect;
      }
    }

    requestAnimationFrameId.current = requestAnimationFrame(run);
  }, [targetElement]);

  useEffect(() => {
    run();

    return () => {
      if (requestAnimationFrameId.current !== undefined) {
        cancelAnimationFrame(requestAnimationFrameId.current);
      }
    };
  }, [run]);
};
