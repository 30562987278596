import { $lastOrder } from 'domains/cart';
import { sample } from 'effector';
import { FELocalStorageUtil } from 'utils';
import { $orders, ordersPageGate } from '../stores';
import { getOrdersFx } from './getOrdersFx';
import { subscribeLastActiveOrderFx } from './subscribeLastActiveOrderFx';

sample({
  clock: getOrdersFx.done,
  source: $lastOrder,
  filter: (lastOrder, { params, result: orders }) => {
    const order = orders[0];

    return (
      params.offset === 0 &&
      lastOrder?.order.id !== order.id &&
      order.status !== 'CANCELLED' &&
      order.status !== 'DONE' &&
      params.limit > 0
    );
  },
  fn: (_, { result: orders }) => {
    const lastOrderId = orders[0].id;

    FELocalStorageUtil.setLastOrderId({ lastOrderId });

    return { orderId: lastOrderId };
  },
  target: subscribeLastActiveOrderFx,
});

sample({
  clock: getOrdersFx.done,
  source: $orders,
  filter: (_, { result: orders }) => !!orders.length,
  fn: (currentOrders, { params, result: orders }) => {
    if (currentOrders.length === 0) {
      return orders;
    }

    const { offset } = params;

    if (offset > currentOrders.length) {
      return [...currentOrders, ...orders];
    }

    const newOrdersArray = [...currentOrders];

    newOrdersArray.splice(offset, orders.length, ...orders);

    return newOrdersArray;
  },
  target: $orders,
});

sample({
  clock: subscribeLastActiveOrderFx.done,
  filter: ({ result }) =>
    result.order.status !== 'DONE' && result.order.status !== 'CANCELLED',
  fn: ({ params }) => params,
  target: subscribeLastActiveOrderFx,
});

sample({
  clock: subscribeLastActiveOrderFx.doneData,
  fn: (response) => {
    const shouldClear =
      response.order.status === 'DONE' || response.order.status === 'CANCELLED';

    if (shouldClear) {
      FELocalStorageUtil.deleteLastOrder();
    }

    return shouldClear ? null : response;
  },
  target: $lastOrder,
});

sample({
  clock: ordersPageGate.open,
  fn: () => ({ limit: 15, offset: 0 }),
  target: getOrdersFx,
});
