import style from './style.module.css';
import React from 'react';
import clsx from 'clsx';
import { getDistanceTo } from 'utils';
import { RestaurantCard } from './components';
import { ListItemProps } from './props';
import { StatusInfo, WorkStatus } from 'models';
import i18next from 'i18next';
import { useGetModalState, usePrepareLink } from 'hooks/router';
import { GET_ENUMS, GET_PARAMS } from 'const';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { AvailableDeliveryTypesBanner } from './components/AvailableDeliveryTypesBanner';

export const getInSchedule = (openAt: string, closeAt: string) =>
  moment(moment().format('YYYY-MM-DD HH:mm')).isBetween(
    moment().format('YYYY-MM-DD') + ` ${openAt}`,
    moment().format('YYYY-MM-DD') + ` ${closeAt}`,
  );

export const getRestaurantStatus = (
  status: WorkStatus,
  statusInfo: StatusInfo,
  openAt: string,
  closeAt: string,
): { title: string; disabled: boolean; showRestaurantInfo: boolean } => {
  if (status === 'CLOSE') {
    return {
      title: i18next.t('cartography.restaurant.statuses.CLOSE'),
      disabled: true,
      showRestaurantInfo: true,
    };
  } else {
    if (statusInfo === 'OPEN') {
      return {
        title: i18next.t('cartography.restaurant.statuses.OPEN', {
          from: openAt,
          to: closeAt,
        }),
        disabled: false,
        showRestaurantInfo: true,
      };
    } else if (statusInfo === 'ONLY_OFFLINE') {
      return {
        title: i18next.t('cartography.restaurant.statuses.ONLY_OFFLINE'),
        disabled: true,
        showRestaurantInfo: true,
      };
    } else {
      return {
        title: i18next.t('cartography.restaurant.statuses.AWAITING_OPEN'),
        disabled: true,
        showRestaurantInfo: false,
      };
    }
  }
};

const ListItem = ({
  restaurant,
  userGeolocation,
  onChangeRestaurant,
  fromCart,
}: ListItemProps) => {
  const [distanceWidth, setDistanceWidth] = React.useState<number>(0);
  const distanceRef = React.useRef<HTMLParagraphElement>(null);

  const { mountedModals } = useGetModalState('restaurant');

  const inSchedule = getInSchedule(restaurant.openAt, restaurant.closeAt);

  const navigate = useNavigate();

  const restaurantLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
      [GET_PARAMS.restaurant]: restaurant.id,
    },
    pushToQuery: fromCart
      ? {
          [GET_PARAMS.page]: GET_ENUMS.page.cart,
        }
      : {},
  });

  React.useLayoutEffect(() => {
    if (distanceRef.current) {
      setDistanceWidth(distanceRef.current.getBoundingClientRect().width);
    }
  }, [userGeolocation, restaurant.address.location]);

  const { title, disabled } = getRestaurantStatus(
    restaurant.status,
    restaurant.statusInfo,
    restaurant.openAt,
    restaurant.closeAt,
  );

  return (
    <>
      <div
        className={style.root}
        onClick={(e) => {
          e.preventDefault();
          navigate(restaurantLink);
        }}
      >
        <div className={style.container}>
          <div className={style.info}>
            <p
              className={clsx('headline')}
              style={{
                paddingRight: `calc(20px + ${distanceWidth}px)`,
              }}
            >
              {restaurant.name}
            </p>
            {userGeolocation && restaurant.address.location && (
              <p ref={distanceRef} className={clsx('text-1', style.distanceTo)}>
                {getDistanceTo(
                  userGeolocation,
                  restaurant.address.location,
                  true,
                )}
              </p>
            )}
          </div>
          <p className={clsx('text-2', style.address)}>
            {restaurant.address.address}
          </p>
          <p
            className={clsx(
              'text-2',
              !disabled && inSchedule ? style.worktime : style.worktime__closed,
            )}
          >
            {title}
          </p>
          <div className={style.orderTypes}>
            {restaurant.availableDeliveryTypes.map((type) => (
              <AvailableDeliveryTypesBanner type={type} key={type} />
            ))}
          </div>
        </div>
      </div>

      <RestaurantCard
        open={mountedModals.includes(restaurant.id)}
        distanceTo={
          userGeolocation && restaurant.address.location
            ? getDistanceTo(userGeolocation, restaurant.address.location, true)
            : undefined
        }
        restaurant={{ ...restaurant }}
        onClose={() => navigate(-1)}
        onPickRestaurant={onChangeRestaurant}
      />
    </>
  );
};

export default ListItem;
