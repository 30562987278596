import clsx from 'clsx';
import { Restaurant } from 'domains/cartography';
import { useTranslation } from 'react-i18next';
import { ArrayType } from 'utils';
import style from './style.module.css';

const GET_TYPE: Record<
  ArrayType<Restaurant['availableDeliveryTypes']>,
  string
> = {
  GO_TO_PLACE: 'С собой',
  IN_PLACE: 'В зале',
};

export const AvailableDeliveryTypesBanner = ({ type }: Props) => {
  const { t } = useTranslation();

  return (
    <span className={clsx('caption-2', style.banner)}>{t(GET_TYPE[type])}</span>
  );
};

type Props = {
  type: ArrayType<Restaurant['availableDeliveryTypes']>;
};
