import clsx from 'clsx';
import { deleteSavedCardFx, SavedCard } from 'domains/savedCards';
import style from './Card.module.css';
import AMERICAN_EXPRESS from 'images/ic/24/american_express.svg';
import JCB from 'images/ic/24/jcb.svg';
import MASTERCARD from 'images/ic/24/mastercard.svg';
import UNION_PAY from 'images/ic/24/unionpay.svg';
import MIR from 'images/ic/24/mir.svg';
import VISA from 'images/ic/24/visa.svg';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { FEButton } from 'ui-kit/components';
import { useTranslation } from 'react-i18next';

const GET_CARD_TYPE_ICON: Record<SavedCard['type'], string> = {
  AMERICAN_EXPRESS: AMERICAN_EXPRESS,
  JCB: JCB,
  MASTERCARD: MASTERCARD,
  UNION_PAY: UNION_PAY,
  MIR: MIR,
  VISA: VISA,
  OTHER: '',
};

export const Card = ({ lastFour, firstSix, type }: Props) => {
  const [isPending, setIsPending] = useState(false);

  const { t } = useTranslation();

  const deleteCard = useUnit(deleteSavedCardFx);

  const handleDelete = () => {
    setIsPending(true);

    const promise = deleteCard({ lastFour, firstSix });

    promise.finally(() => {
      setIsPending(false);
    });
  };

  return (
    <div className={style.card}>
      <img
        className={style.typeIcon}
        src={GET_CARD_TYPE_ICON[type]}
        alt={type}
      />

      <FEButton
        disabled={isPending}
        showLoader={isPending}
        onClick={handleDelete}
        type='secondary'
        className={style.deleteButton}
        size='medium'
      >
        {t('profile.cardDelete')}
      </FEButton>

      <p className={clsx('text-1', style.mask)}>{`**** ${lastFour}`}</p>
    </div>
  );
};

type Props = Pick<SavedCard, 'lastFour' | 'firstSix' | 'type'>;
