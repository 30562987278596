import { useMount } from 'hooks';
import React from 'react';
import { FEPortal } from 'ui-kit/components';
import { ModalContainer } from './ModalContainer';

export const CodeModal = ({ open, ...rest }: Props) => {
  const { mounted } = useMount({ isOpened: open });

  if (!mounted) return null;

  return (
    <FEPortal>
      <ModalContainer {...rest} />
    </FEPortal>
  );
};

type Props = {
  phone: string;
  open: boolean;
  onClose: () => void;
};
