import { GetCustomerResponseV1 } from 'api/version1';
import // $lastOrder,
// getOrderLongPollFx,
'domains/cart';
import { sample } from 'effector';
import { AuthClient } from 'api/base';
import {
  // debounce,
  spread,
} from 'patronum';
import {
  getUserProfileFx,
  getBonusAccountFx,
  getBonusAccountTransactionsFx,
  updateUserProfileFx,
  // getOrdersFx,
  initUserFx,
  // $pendingGetOrders,
  // getOrdersWithParamsFx,
} from './effects';
import {
  //  getOrdersList,
  logOutUser,
} from './events';
import {
  $user,
  $bonusAccount,
  $bonusAccountTransactions,
  EditProfilePageGate,
  profileForm,
  // ordersPageGate,
  // $orders,
  // $customerOrdersCount,
} from './stores';
import { FEHeadersCookiesUtil } from 'utils';
import { getSavedCardsFx } from 'domains/savedCards';
import './effects/init';

spread({
  source: sample({
    clock: initUserFx.doneData,
    fn: ({ customer, bonusAccount, bonusAccountTransactions }) => {
      return { customer, bonusAccount, bonusAccountTransactions };
    },
  }),
  targets: {
    customer: $user,
    bonusAccount: $bonusAccount,
    bonusAccountTransactions: $bonusAccountTransactions,
  },
});

sample({
  clock: initUserFx.doneData,
  filter: () => AuthClient.isAuth,
  target: getSavedCardsFx,
});

sample({
  clock: getUserProfileFx.doneData,
  fn: (payload): GetCustomerResponseV1 => ({
    header: payload.header || null,
    fullName: payload.fullName || null,
    avatarId: payload.avatarId || null,
    phone: payload.phone,
    sex: payload.sex,
    birthday: payload.birthday || null,
    birthdayModerate: payload.birthdayModerate || null,
    cityId: payload.cityId,
    email: payload.email || null,
    socialAccounts: payload.socialAccounts || [],
  }),
  target: $user,
});
sample({
  clock: updateUserProfileFx.doneData,
  fn: (payload): GetCustomerResponseV1 => ({
    header: payload.header || null,
    fullName: payload.fullName || null,
    avatarId: payload.avatarId || null,
    phone: payload.phone,
    sex: payload.sex,
    birthday: payload.birthday || null,
    birthdayModerate: payload.birthdayModerate || null,
    cityId: payload.cityId,
    email: payload.email || null,
    socialAccounts: payload.socialAccounts || [],
  }),
  target: $user,
});
sample({
  clock: getUserProfileFx.doneData,
  target: [getBonusAccountFx, getSavedCardsFx],
});
sample({
  clock: getUserProfileFx.failData,
  fn: () => null,
  target: $user,
});
sample({
  clock: getUserProfileFx.failData,
  fn: () => null,
  target: $bonusAccount,
});

//@ts-expect-error
spread({
  source: sample({
    clock: getBonusAccountFx.doneData,
    fn: (payload) => ({
      bonusAccount: payload.items[0],
      bonusAccountId: { id: payload.items[0].id },
    }),
  }),
  targets: {
    bonusAccount: $bonusAccount,
    bonusAccountId: getBonusAccountTransactionsFx,
  },
});

sample({
  clock: getBonusAccountTransactionsFx.doneData,
  fn: (payload) => payload.items,
  target: $bonusAccountTransactions,
});

//@ts-expect-error
spread({
  source: sample({
    clock: getBonusAccountFx.failData,
    fn: () => ({
      bonusAccount: null,
      bonusAccountTransactions: [],
    }),
  }),
  targets: {
    bonusAccount: $bonusAccount,
    bonusAccountId: $bonusAccountTransactions,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: logOutUser,
    fn: () => {
      AuthClient.clearCookies();
      FEHeadersCookiesUtil.clearCountryCode();
      FEHeadersCookiesUtil.clearPhoneMask();
      return {
        userAccount: null,
        bonusAccount: null,
        bonusAccountTransactions: [],
      };
    },
  }),
  targets: {
    userAccount: $user,
    bonusAccount: $bonusAccount,
    bonusAccountTransactions: $bonusAccountTransactions,
  },
});

sample({
  clock: EditProfilePageGate.open,
  source: $user,
  filter: (user) => user !== null,
  fn: (user) => {
    const { phone, sex, fullName, email, birthday } = user!;
    return {
      phone,
      sex,
      email,
      birthday,
      lastName: fullName?.lastName || null,
      firstName: fullName?.firstName || null,
    };
  },
  target: profileForm.set,
});
sample({
  clock: EditProfilePageGate.close,
  target: profileForm.reset,
});
// sample({
//   clock: ordersPageGate.open,
//   target: getOrdersFx,
// });

// spread({
//   source: sample({
//     clock: getOrdersFx.doneData,
//     fn: (payload) => ({
//       ...payload,
//     }),
//   }),
//   targets: {
//     ordersCount: $customerOrdersCount,
//     orders: $orders,
//     lastOrder: $lastOrder,
//   },
// });

// getOrdersFx.doneData.watch((res) => {
//   if (res.needToFetchLastOrder) {
//     getOrderLongPollFx({ id: res.lastOrder!.order.id, timeout: 25 });
//   }
// });

// sample({
//   clock: debounce({
//     source: getOrdersList,
//     timeout: 200,
//   }),
//   source: { $pendingGetOrders, $customerOrdersCount },
//   filter: ({ $pendingGetOrders, $customerOrdersCount }, { offset }) =>
//     !$pendingGetOrders && $customerOrdersCount !== null
//       ? offset < $customerOrdersCount
//       : true,
//   fn: (_, params) => params,
//   target: getOrdersWithParamsFx,
// });

// sample({
//   clock: getOrdersWithParamsFx.doneData,
//   source: $orders,
//   fn: (state, data) => [...state, ...data],
//   target: $orders,
// });
