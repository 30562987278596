import React from 'react';
import clsx from 'clsx';
import style from './style.module.css';
import FETabButtonMobileProps from "./props";

export default React.forwardRef<HTMLButtonElement, FETabButtonMobileProps>(
  ({ children, onClick, active, disabled = false, onChangeActive }, ref) => {
    React.useEffect(() => {
      onChangeActive && onChangeActive(active);
    }, [active, onChangeActive]);

    return (
      <button
        className={clsx(style.root, active && style.active)}
        disabled={disabled}
        onClick={(e) => {
          onClick(e);
          // e.preventDefault();
          // e.stopPropagation();
        }}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export type { FETabButtonMobileProps };
