import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FEOverlay } from 'ui-kit/components';
import { ANIMATION_DELAY } from 'const';
import clsx from 'clsx';
import style from './ModalContainer.module.css';
import { useTranslation } from 'react-i18next';

const QRCode = require('qrcodejs2');

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

export const ModalContainer = ({ phone, onClose }: Props) => {
  const { t } = useTranslation();

  const [animationIn, setAnimationIn] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const codeContainerRef = useRef<HTMLDivElement>(null);

  const codeInstanceRef = useRef();

  const onMount = useCallback(() => {
    if (!codeInstanceRef.current && phone) {
      codeInstanceRef.current = new QRCode(codeContainerRef.current, {
        text: phone,
        width: 306,
        height: 306,
        colorDark: '#000000',
        colorLight: 'transparent',
        correctLevel: QRCode.CorrectLevel.H,
      });
    }
  }, [phone]);

  const onUnmount = useCallback(() => {
    //@ts-expect-error
    codeInstanceRef.current && codeInstanceRef.current.clear();
  }, []);

  useEffect(() => {
    setAnimationIn(true);
  }, []);

  const handleClose = () => {
    onClose();
    setAnimationIn(false);
  };

  return (
    <div className={style.dialog} role='dialog'>
      <FEOverlay animationIn={animationIn} onClick={handleClose} />
      <CSSTransition
        onEnter={onMount}
        onExited={onUnmount}
        in={animationIn}
        timeout={ANIMATION_DELAY}
        mountOnEnter
        unmountOnExit
        classNames={containerAnimation}
        nodeRef={containerRef}
      >
        <div className={style.container} ref={containerRef}>
          <div className={style.content}>
            <div className={style.header}>
              <h1 className={clsx('heading2', style.title)}>{t('QR-код')}</h1>
              <button
                onClick={handleClose}
                className={clsx('closeButton-mobile', style.closeButton)}
              />
            </div>
            <div className={style.code}>
              <div className={style.codeWrapper} ref={codeContainerRef} />
            </div>
            <p className={style.message}>
              {t(
                `Покажи кассиру QR-код,
либо назови номер телефона`,
              )}
            </p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

type Props = {
  phone: string;
  onClose: () => void;
};
