import React from 'react';
import {
  // FENotificationCard,
  FEScrollableTitledBottomSheet,
  FEScrollableTitledDialog,
  FEButton,
  FEImageComponent,
} from 'components';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import style from './style.module.css';
import clsx from 'clsx';
import { IMG_PLACEHOLDER_SMALL_PICTURE } from 'images';
import { RestaurantCardProps } from './props';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Mousewheel } from 'swiper';
import { getInSchedule, getRestaurantStatus } from '../..';
import { useTranslation } from 'react-i18next';
import { Restaurant } from 'domains/cartography';
import { AvailableDeliveryTypesBanner } from '../AvailableDeliveryTypesBanner';

const Content = ({
  onClose,
  // onToggleScheduleList,
  onPickRestaurant,
  onCloseModal,
  // openSchedule,
  showRestaurantInfo,
  restaurant,
  distanceTo,
  disabled,
  title,
}: {
  onClose: () => void;
  onCloseModal: () => void;
  // onToggleScheduleList: () => void;
  onPickRestaurant: (restaurant: Restaurant) => void;
  // openSchedule: boolean;
  disabled: boolean;
  title: string;
  distanceTo: string | number | undefined;
  showRestaurantInfo: boolean;
  restaurant: Restaurant;
}) => {
  const { t } = useTranslation();
  const inSchedule = getInSchedule(restaurant.openAt, restaurant.closeAt);

  return (
    <>
      <div className={style.root}>
        <div className={style.header}>
          <div className={style.headerContent}>
            <div className={style.info}>
              <p
                className={clsx(
                  'headline',
                  !disabled && inSchedule
                    ? style.worktime
                    : style.worktime__Closed,
                )}
              >
                {title}
              </p>
              {distanceTo && (
                <p className={clsx('text-1', style.distanceTo)}>{distanceTo}</p>
              )}
            </div>
            <p className={clsx('text-2', style.address)}>
              {restaurant.address.address}
            </p>
            <div className={style.orderTypes}>
              {restaurant.availableDeliveryTypes.map((type) => (
                <AvailableDeliveryTypesBanner type={type} key={type} />
              ))}
            </div>
          </div>
        </div>
        {showRestaurantInfo && (
          <>
            <div className={style.categories}>
              <div className={style.categoriesHeader}>
                <p className='subhead'>
                  {t(
                    'cartography.restaurant.restaurantCard.cookAtRestaurantLabel',
                  )}
                </p>
              </div>
              <Swiper
                initialSlide={0}
                spaceBetween={12}
                navigation={true}
                mousewheel={true}
                modules={[Navigation, Mousewheel]}
                direction='horizontal'
                slidesPerView={'auto'}
                className={style.categoriesGallery}
              >
                {restaurant.categories.map((category) => (
                  <SwiperSlide
                    key={category.categoryId}
                    className={style.category}
                  >
                    <FEImageComponent
                      image={{ imageId: category.imageId }}
                      altImage={IMG_PLACEHOLDER_SMALL_PICTURE}
                      className={style.categoryImage}
                    />
                    <p className={clsx('caption-1', style.categoryTitle)}>
                      {category.title}
                    </p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className={clsx(
                style.scheduleContainer,
                !disabled && style.scheduleContainer__closed,
              )}
            >
              {/* <div
                  className={clsx(
                    style.scheduleContent,
                    !disabled && style.scheduleContent__closed,
                  )}
                >
                  <p className={clsx('subhead', style.scheduleHeader)}>
                    {t(
                      'cartography.restaurant.restaurantCard.scheduleLabel',
                    )}
                  </p>
                  <div
                    className={style.scheduleListHeader}
                    onClick={onToggleScheduleList}
                  >
                    <p className='text-2'>
                      {t(
                        'cartography.restaurant.restaurantCard.restaurantLabel',
                      )}
                    </p>
                    <button
                      className={clsx(
                        'button-chevronDown',
                        openSchedule && style.button__chevron__openChedule,
                      )}
                    />
                  </div>
                  {openSchedule && (
                    <div className={style.scheduleListContent}>
                      {[
                        t('daysOfWeek.monday'),
                        t('daysOfWeek.tuesday'),
                        t('daysOfWeek.wednesday'),
                        t('daysOfWeek.thursday'),
                        t('daysOfWeek.friday'),
                        t('daysOfWeek.saturday'),
                        t('daysOfWeek.sunday'),
                      ].map((day) => (
                        <div key={day} className={style.scheduleListItem}>
                          <p className='text-2'>{day}</p>
                          <p className='text-2'>{`${openAt}-${closeAt}`}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div> */}
            </div>
          </>
        )}
      </div>
      <div className={style.submit}>
        {!disabled && (
          <FEButton
            children={t(
              'cartography.restaurant.restaurantCard.buttonsLabel.pickHere',
            )}
            onClick={() => {
              onPickRestaurant(restaurant);
              onCloseModal();
            }}
          />
        )}
        <FEButton
          type='secondary'
          children={t(
            'cartography.restaurant.restaurantCard.buttonsLabel.backToTheList',
          )}
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </>
  );
};

const RestaurantCard = ({
  open,
  onClose,
  onPickRestaurant,
  restaurant,
  distanceTo,
}: RestaurantCardProps) => {
  // const [openSchedule, setOpenSchedule] = React.useState<boolean>(false);

  const { openAt, closeAt, name, status, statusInfo } = restaurant;

  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  const { title, disabled, showRestaurantInfo } = getRestaurantStatus(
    status,
    statusInfo,
    openAt,
    closeAt,
  );

  return (
    <>
      {isDesktop ? (
        <>
          <FEScrollableTitledDialog
            // showCloseButton={false}
            containerClassName={style.rootContainer}
            open={open}
            onClose={onClose}
            title={name}
            render={({ onClose, onCloseModal }) => (
              <Content
                onCloseModal={onCloseModal}
                onClose={onClose}
                onPickRestaurant={onPickRestaurant}
                disabled={disabled}
                title={title}
                distanceTo={distanceTo}
                showRestaurantInfo={showRestaurantInfo}
                restaurant={restaurant}
              />
            )}
          />
        </>
      ) : (
        <>
          <FEScrollableTitledBottomSheet
            containerClassName={style.rootContainer}
            open={open}
            onClose={onClose}
            title={name}
            render={({ onClose, onCloseModal }) => (
              <Content
                onCloseModal={onCloseModal}
                onClose={onClose}
                onPickRestaurant={onPickRestaurant}
                disabled={disabled}
                title={title}
                distanceTo={distanceTo}
                showRestaurantInfo={showRestaurantInfo}
                restaurant={restaurant}
              />
            )}
          />
        </>
      )}
      {/* <FENotificationCard
        icon='restaurantClosed'
        title='Ресторан закрыт'
        description={`Мы откроемся в ${openAt}, а пока можешь оформить предзаказ`}
        open={openClosed && open}
        onClose={() => setOpenClosed(false)}
        primaryButtonProps={{
          onClick: () => {},
          text: 'Продолжить',
          closeOnClick: true,
        }}
      /> */}
    </>
  );
};

export default RestaurantCard;
