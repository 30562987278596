import clsx from 'clsx';
import {
  $city,
  $hasRestaurantsError,
  $isPendingRestaurants,
  $noRestaurantsInCityError,
  $restaurantsList,
  $userGeolocation,
  fetchRestaurantsFx,
  Restaurant,
} from 'domains/cartography';
import { useUnit } from 'effector-react';
import { useEvent } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FESpinner } from 'ui-kit/components';
import { useMediaQuery } from 'ui-kit/hooks';
import { DesktopRestaurantsLayout, MobileRestaurantsLayout } from './layouts';
import { RestaurantsLayoutProps } from './layouts/props';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const RestaurantsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentRestaurant, setCurrentRestaurant] =
    React.useState<Restaurant | null>(null);
  const [state, setState] = React.useState<'INFO' | 'MAP'>('INFO');

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [restaurantsList, setRestaurantsList] = React.useState<Restaurant[]>(
    [],
  );
  const deferredList = React.useDeferredValue<Restaurant[]>(restaurantsList);

  const is1440 = useMediaQuery(`(min-width: 1440px)`);
  const is1024 = useMediaQuery(`(min-width: 1024px)`);

  const [
    restaurants,
    hasError,
    noRestaurantsInCityError,
    city,
    userGeolocation,
    isPendingRestaurants,
    handleFetchRestaurants,
  ] = useUnit([
    $restaurantsList,
    $hasRestaurantsError,
    $noRestaurantsInCityError,
    $city,
    $userGeolocation,
    $isPendingRestaurants,
    fetchRestaurantsFx,
  ]);

  React.useEffect(() => setRestaurantsList(restaurants), [restaurants]);

  const changeQueryHandler = useEvent(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      setSearchValue(val);
      if (val) {
        const newRestaurantsList = restaurants.filter(
          (restaurant) =>
            restaurant.name.toLowerCase().includes(val.toLowerCase()) ||
            restaurant.address.address
              .toLowerCase()
              .includes(val.toLowerCase()),
        );
        setRestaurantsList(newRestaurantsList);
      } else setRestaurantsList(restaurants);
    },
  );

  const handleClearSearchValue = () => {
    setSearchValue('');
    setRestaurantsList(restaurants);
  };

  const props: RestaurantsLayoutProps = {
    restaurantsisPending: isPendingRestaurants,
    fetchRestaurants: () => handleFetchRestaurants({ cityId: city!.id }),
    onClearSearchValue: handleClearSearchValue,
    searchValue,
    onChangeSearchValue: changeQueryHandler,
    restaurants: deferredList,
    currentRestaurant: currentRestaurant,
    onPickRestaurant: setCurrentRestaurant,
    onCloseRestaurant: () => {
      setState('INFO');
      setCurrentRestaurant(null);
    },
    hasError: hasError,
    noRestaurantsError: noRestaurantsInCityError,
    userGeolocation,
  };

  return (
    <>
      {city && (
        <Helmet>
          <title>{t('meta.restaurants.title', { city: city.name })}</title>
          <meta
            name='description'
            content={t('meta.restaurants.metaDescription', {
              city: city.name,
            })}
          />
        </Helmet>
      )}

      <div className={style.root}>
        {city ? (
          <>
            <div className={style.breadCrumbs}>
              <span
                className='text-2'
                onClick={() => {
                  navigate('/');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                {t('restaurants.home')}
              </span>
              <span className='text-2'>/</span>
              <span className='text-2'>{t('restaurants.title')}</span>
            </div>
            <h1 className={clsx(is1440 ? 'heading1' : 'heading2', style.title)}>
              {t('restaurants.title')}
            </h1>
            <div className={style.restaurantsLayout}>
              {is1024 ? (
                <DesktopRestaurantsLayout
                  center={{
                    lat: city.location.latitude,
                    lng: city.location.longitude,
                  }}
                  immutableRestaurantsList={restaurants}
                  {...props}
                />
              ) : (
                <MobileRestaurantsLayout
                  {...props}
                  state={state}
                  onChangeState={(state) => setState(state)}
                />
              )}
            </div>
          </>
        ) : (
          <div className={style.loaderContainer}>
            <FESpinner size='large' />
          </div>
        )}
      </div>
    </>
  );
};

export default RestaurantsPage;
