import {
  $placeSavedCards,
  // SavedCard
} from 'domains/savedCards';
import { useUnit } from 'effector-react';
// import { useEffect, useState } from 'react';

// type Cards = { [mask: string]: SavedCard };

export const useCards = () => {
  // const [cards, setCards] = useState<SavedCard[]>([]);

  const cards = useUnit($placeSavedCards);

  // useEffect(() => {
  //   if (savedCards.length) {
  //     const cards: Cards = {};
  //     for (const savedCard of savedCards) {
  //       const mask = savedCard.firstSix + savedCard.lastFour;
  //       if (!cards[mask]) {
  //         cards[mask] = savedCard;
  //       }
  //     }
  //     setCards(Object.values(cards));
  //   }
  // }, [savedCards]);

  return {
    cards,
  };
};
