import { MOBILE_WIDTH } from 'const';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'ui-kit/hooks';
import {
  FEScrollableTitledBottomSheet,
  FEScrollableTitledDialog,
} from 'components';
import { Content } from './components';
import { useUnit } from 'effector-react';
import { $bonusAccountTransactions } from 'domains/profile';
import style from './style.module.css';
import { AuthClient } from 'api/base';
import { useLinks } from 'hooks/router';
import { useTranslation } from 'react-i18next';

const BonusPage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);

  const [transactions] = useUnit([$bonusAccountTransactions]);

  const { authLink } = useLinks();

  React.useEffect(() => {
    if (AuthClient.isAuth === false) {
      navigate(authLink, { replace: true });
    }
  }, [authLink]);

  return isDesktop ? (
    <FEScrollableTitledDialog
      containerClassName={style.container}
      title={t('bonus.page.title')}
      onArrowBackClick={showInfo ? () => setShowInfo(false) : undefined}
      open={open}
      onClose={() => navigate(-1)}
      render={({ onClose }) => (
        <Content
          {...{
            transactions,
            showInfo,
            onChangeShowInfo: (v) => setShowInfo(v),
          }}
        />
      )}
    />
  ) : (
    <FEScrollableTitledBottomSheet
      containerClassName={style.container}
      showCloseButton={false}
      title={t('bonus.page.title')}
      onArrowBackClick={showInfo ? () => setShowInfo(false) : undefined}
      open={open}
      onClose={() => navigate(-1)}
      render={({ onClose }) => (
        <Content
          {...{
            transactions,
            showInfo,
            onChangeShowInfo: (v) => setShowInfo(v),
          }}
        />
      )}
    />
  );
};

export default BonusPage;
