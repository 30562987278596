import clsx from 'clsx';
import React from 'react';
import FEBarProps from './props';
import style from './style.module.css';

const FEBar = ({
  label,
  value,
  onClick,
  placeholder,
  valueIcon,
  theme = 'primary',
    color = 'black'
}: FEBarProps) => {
  return (
    <div className={theme === "primary" ? style.root : style.rootOutline} onClick={onClick}>
      <div className={style.container}>
        <div className={style.data}>
          <p className={clsx('caption-1', color === 'black' ? style.label : style.labelWhite)}>{label}</p>
          <div className={style.valueContainer}>
            <p className={clsx('headline',  color === 'black' ? style.value : style.valueWhite)}>
              {value ?? placeholder}
            </p>
            {value !== null && valueIcon !== undefined && (
              <img src={valueIcon} alt='' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FEBar;
export type { FEBarProps };
