import { Language } from 'models';
import { CSSTransition } from 'react-transition-group';
import style from '../style.module.css';
import { useState, useRef, useEffect } from 'react';
import { ANIMATION_DELAY } from 'const';
import { useClickOutside } from 'ui-kit/hooks';
import { FERadioButton } from 'components';
import usaFlag from '../usa.svg';
import ruFlag from '../ru.svg';
import ArFlag from '../armenia.svg';
import KzFlag from '../kaz.svg';
import { useTranslation } from 'react-i18next';

const containerAnimation = {
  enter: style.containerEnter,
  enterActive: style.containerEnterActive,
  exit: style.containerExit,
  exitActive: style.containerExitActive,
};

type LanguageObjModel = {
  label: string;
  flagUrl: string;
};

const languages: Record<Language, LanguageObjModel> = {
  ru: {
    label: 'Русский',
    flagUrl: ruFlag,
  },
  en: {
    label: 'English',
    flagUrl: usaFlag,
  },
  hy: {
    label: 'հայերէն',
    flagUrl: ArFlag,
  },
  kz: { 
    label: 'Қазақ тілі',
    flagUrl: KzFlag
  },
};

export const Layout = ({
  onClose,
  onChangeLanguage,
  currentLanguage,
}: {
  onClose: () => void;
  onChangeLanguage: (language: Language) => void;
  currentLanguage: Language;
}) => {
  const { t } = useTranslation();
  const [animationIn, setAnimationIn] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => setAnimationIn(true), []);

  useClickOutside(containerRef, handleClose);

  function handleClose() {
    if (onClose) {
      onClose();
      setAnimationIn(false);
    }
  }

  return (
    <CSSTransition
      in={animationIn}
      timeout={ANIMATION_DELAY}
      mountOnEnter
      unmountOnExit
      classNames={containerAnimation}
      nodeRef={containerRef}
    >
      <div className={style.container} ref={containerRef}>
        <div className={style.content}>
          <div className={style.header}>
            <p className='title'>{t('language.title')}</p>
          </div>
          <div className={style.list}>
            {Object.keys(languages).map((key) => {
              const { label, flagUrl } = languages[key as Language];
              return (
                <div
                  onClick={() =>
                    key !== currentLanguage && onChangeLanguage(key as Language)
                  }
                  className={style.listItem}
                  key={key}
                >
                  <div className={style.listItemContent}>
                    <img src={flagUrl} className={style.flag} alt='' />
                    <p>{label}</p>
                    <div className={style.radio}>
                      <FERadioButton active={key === currentLanguage} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
