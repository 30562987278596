import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { AxiosResponse } from 'axios';
import { BASE_URL } from 'const';
import { FEHeadersCookiesUtil, FERequestErrorsHandler } from 'utils';

const signRepositoryV1 = {
  getSign: () => {
    return new Promise<{ sign: string }>(async (resolve, reject) => {
      try {
        let url = `${BASE_URL}/not-secure/api/v1/sign`;
        let result: AxiosResponse<BaseAPIResponse<{ code: string }>> =
          await BaseAPIRepository.get(url);

        FEHeadersCookiesUtil.setSign(result.data.result.code);
        resolve({ sign: result.data.result.code });
      } catch (error: any) {
        FERequestErrorsHandler(error, reject);
      }
    });
  },
};

export default signRepositoryV1;
