import { createEffect, sample } from 'effector';
import { GetSavedCardsResponse } from '../api/GetSavedCardsResponse';
import { savedCardsApi } from '../api/savedCardsApi';
import { $savedCards } from '../stores';

export const getSavedCardsFx = createEffect<void, GetSavedCardsResponse>(() =>
  savedCardsApi.getSavedCards(),
);

sample({
  clock: getSavedCardsFx.doneData,
  fn: (res) => res.items,
  target: $savedCards,
});
