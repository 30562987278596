import { GET_ENUMS, GET_PARAMS, INT_PROD_URL, MOBILE_WIDTH, URL } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEBottomSheet, FEDialog } from 'components';
import { useNavigate } from 'react-router-dom';
import { Layout } from './layouts';
import {
  $address,
  $citiesList,
  $city,
  $countriesList,
  $country,
  $isPendingCities,
  $restaurant,
  $type,
  $userGeolocation,
  cartographyGate,
  changeCountry,
  City,
  Country,
} from 'domains/cartography';
import { useGate, useUnit } from 'effector-react';
import { useGetModalState, usePrepareLink } from 'hooks/router';
import { changeCity } from 'domains/cartography';
import { toASCII } from 'punycode';
import { initAdmitadCrossdomainScript } from 'utils';
import { OrderType } from 'models';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

const CartographyPage = ({ open }: { open: boolean }) => {
  const { mountedModals: mountedLayouts } = useGetModalState('layout');
  const { mountedModals: mountedPages } = useGetModalState('page');
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const navigate = useNavigate();
  const { search } = useLocation();

  const fromCart = mountedPages.includes('cart');

  const [type, setType] = useState<Exclude<OrderType, 'IN_PLACE'> | null>(null);

  const [countriesList, country, citiesList, city, userGeolocation, orderType] =
    useUnit([
      $countriesList,
      $country,
      $citiesList,
      $city,
      $userGeolocation,
      $type,
    ]);

  useEffect(() => {
    if (type === null) {
      const query = new URLSearchParams(search);
      const searchType = query.get('type');
      if (searchType) setType(searchType as Exclude<OrderType, 'IN_PLACE'>);
      else setType(orderType as Exclude<OrderType, 'IN_PLACE'>);
    }
  }, [orderType, type, search]);

  const isPendingCities = useUnit($isPendingCities);

  const onChangeCountry = useUnit(changeCountry);
  const onChangeCity = useUnit(changeCity);

  useGate(cartographyGate);

  const closable = [
    city,
    country,
    useUnit([$address, $restaurant]).some((val) => !!val),
  ].every((val) => Boolean(val));

  const cartographyLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
    },
    pushToQuery: fromCart
      ? {
          [GET_PARAMS.page]: GET_ENUMS.page.cart,
        }
      : {},
  });
  const countryLayoutLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
      [GET_PARAMS.layout]: 'country',
    },
    pushToQuery: fromCart
      ? {
          [GET_PARAMS.page]: GET_ENUMS.page.cart,
        }
      : {},
  });
  const cityLayoutLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
      [GET_PARAMS.layout]: 'city',
    },
    pushToQuery: fromCart
      ? {
          [GET_PARAMS.page]: GET_ENUMS.page.cart,
        }
      : {},
  });
  const cityLayoutAfterCountryLink = usePrepareLink({
    query: {
      [GET_PARAMS.page]: GET_ENUMS.page.cartography,
      [GET_PARAMS.layout]: 'city',
      separate: 'false',
    },
    pushToQuery: fromCart
      ? {
          [GET_PARAMS.page]: GET_ENUMS.page.cart,
        }
      : {},
  });

  const handleChangeCountry = (country: Country) => {
    if (
      country.name === 'United Arab Emirates' &&
      process.env.NODE_ENV === 'production'
    ) {
      window.open(`https://${INT_PROD_URL}/`, '_self');
    } else {
      onChangeCountry(country);
      navigate(cityLayoutAfterCountryLink, { replace: true });
    }
  };

  const handleChangeCity = (city: City) => {
    if (process.env.NODE_ENV === 'production') {
      initAdmitadCrossdomainScript();
      window.open(`https://${toASCII(city.code)}.${URL}/`, '_self');
    } else {
      onChangeCity(city);
      navigate(cartographyLink, { replace: true });
    }
  };

  return (
    <>
      {isDesktop ? (
        <>
          <FEDialog
            open={open}
            onClose={() => navigate(-1)}
            closeOnOverlayClick={closable}
            showCloseButton={closable}
            render={({ onClose, onCloseModal }) => (
              <Layout
                {...{
                  fromCart,
                  onCloseModal,
                  onChangeType: (type) =>
                    setType(type as Exclude<OrderType, 'IN_PLACE'>),
                  type,
                  isPendingCities,
                  isPendingCountries: false,
                  onCloseCityLayout: () =>
                    navigate(cartographyLink, { replace: true }),
                  onCloseCountryLayout: () =>
                    navigate(cartographyLink, { replace: true }),
                  onChangeCity: handleChangeCity,
                  onChangeCountry: handleChangeCountry,
                  countriesList,
                  citiesList,
                  isOpenCityLayout: mountedLayouts.includes('city'),
                  isOpenCountryLayout: mountedLayouts.includes('country'),
                  country,
                  city,
                  userGeolocation,
                  onClose,
                  onOpenCountryLayout: () =>
                    navigate(countryLayoutLink, { replace: true }),
                  onOpenCityLayout: () =>
                    navigate(cityLayoutLink, { replace: true }),
                }}
              />
            )}
          />
        </>
      ) : (
        <>
          <FEBottomSheet
            open={open}
            onClose={closable ? () => navigate(-1) : undefined}
            closeOnOverlayClick={closable}
            render={({ onClose, onCloseModal }) => (
              <Layout
                {...{
                  fromCart,
                  onCloseModal,
                  type,
                  onChangeType: (type) =>
                    setType(type as Exclude<OrderType, 'IN_PLACE'>),
                  isPendingCities,
                  isPendingCountries: false,
                  onCloseCityLayout: () =>
                    navigate(cartographyLink, { replace: true }),
                  onCloseCountryLayout: () =>
                    navigate(cartographyLink, { replace: true }),
                  onChangeCity: handleChangeCity,
                  onChangeCountry: handleChangeCountry,
                  countriesList,
                  citiesList,
                  isOpenCityLayout: mountedLayouts.includes('city'),
                  isOpenCountryLayout: mountedLayouts.includes('country'),
                  country,
                  city,
                  userGeolocation,
                  onClose,
                  onOpenCountryLayout: () =>
                    navigate(countryLayoutLink, { replace: true }),
                  onOpenCityLayout: () =>
                    navigate(cityLayoutLink, { replace: true }),
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default CartographyPage;
