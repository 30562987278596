import React from 'react';
import { IMG_IC_24_PROFILE_NEW } from 'images';
import style from './style.module.css';
import { PROFILE_PATHNAME } from 'const';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUnit } from 'effector-react';
import { $bonusAccount } from 'domains/profile';
import clsx from 'clsx';

const Menu = () => {
  const { t } = useTranslation();

  const [
    bonusAccount
  ] = useUnit([
    $bonusAccount
  ])
  
  
  const navigate = useNavigate();

  return (
      <button
        className={clsx(style.openButton, style.profileContainer, style.root)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(PROFILE_PATHNAME);
        }}
      >
        
        {bonusAccount && <p className={style.bonusLabel}>
          {bonusAccount ? bonusAccount.balance : 0}
        </p>}
          <img
            src={IMG_IC_24_PROFILE_NEW}
            alt=''
            className={style.profileImg}
          />
          <p className={clsx('caption-2')}>{t('Профиль')}</p>
      </button>
  );
};

export default Menu;
