import {Language as LanguageModel} from 'models';
import {IMG_IC_16_LANGUAGE_TERTIARY} from 'images';
import {Block} from '..';
import {useState} from 'react';
import {LanguagePicker} from './components/languagePicker';

const GET_LANGUAGE: Record<LanguageModel, 'RU' | 'EN' | "HY" | "KZ"> = {
    en: 'EN',
    ru: 'RU',
    hy: 'HY',
    kz: 'KZ',
};

const Language = ({
                      language,
                      className,
                      onChangeLanguage,
                  }: {
    language: LanguageModel;
    className: string;
    onChangeLanguage: (language: LanguageModel) => void;
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>

            <Block
                icon={IMG_IC_16_LANGUAGE_TERTIARY}
                value={GET_LANGUAGE[language]}
                {...{onClick: () => setOpen(!open), className}}
            />
            <LanguagePicker
                currentLanguage={language}
                onChangeLanguage={onChangeLanguage}
                open={open}
                onClose={() => setOpen(false)}
            />


        </>
    );
};

export default Language;
