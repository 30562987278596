import clsx from 'clsx';
import { Order } from 'domains/profile';
import { CurrencySymbol, OrderStatus, OrderType } from 'models';
import { FEButton } from 'components';
import style from './style.module.css';
import { Product } from './components';
import { getTime } from '../../../..';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Content = ({
  order,
  onRepeatOrder,
  onClose,
  currency,
}: {
  order: Order;
  onRepeatOrder: ({ order }: { order: Order }) => void;
  onClose: () => void;
  currency: CurrencySymbol;
}) => {
  const { t } = useTranslation();
  const statuses: Record<OrderType, Record<OrderStatus, string>> = {
    COURIER: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: t('orders.statuses.ON_WAY'),
      WAIT: t('orders.statuses.WAIT_COURIER'),
    },
    GO_TO_PLACE: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: '',
      WAIT: t('orders.statuses.WAIT_GO_TO_PLACE'),
    },
    IN_PLACE: {
      PROCESSING: t('orders.statuses.PROCESSING'),
      CANCELLED: t('orders.statuses.CANCELLED'),
      COOKING: t('orders.statuses.COOKING'),
      DONE: t('orders.statuses.DONE'),
      IN_PROGRESS: t('orders.statuses.IN_PROGRESS'),
      ON_WAY: '',
      WAIT: t('orders.statuses.WAIT_GO_TO_PLACE'),
    },
  };

  const getStatus = (
    type: OrderType,
    status: OrderStatus,
    dateTimeTo: string,
  ) => {
    if (status !== 'CANCELLED' && moment(dateTimeTo).isAfter(moment(), 'day')) {
      return t('orders.statuses.PREORDER');
    }
    return statuses[type][status];
  };

  const getColor: Record<OrderStatus, string> = {
    PROCESSING: style.orange,
    CANCELLED: style.red,
    COOKING: style.orange,
    DONE: style.green,
    IN_PROGRESS: style.orange,
    ON_WAY: style.orange,
    WAIT: style.orange,
  };
  return (
    <>
      <div className={style.root}>
        <div className={style.header}>
          <button
            tabIndex={0}
            className='button-arrowBack'
            onClick={(e) => {
              onClose();
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <h3 className='title'>{t('orders.title')}</h3>
        </div>
        <div className={style.heading}>
          <p className='headline'>
            {t('orders.orderNumberLabel', { orderNumber: order.number })}
          </p>
        </div>
        <div className={style.data}>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>{t('orders.statusLabel')}</p>
            <p className={clsx(getColor[order.status])}>
              {order.status === 'DONE' || order.status === 'CANCELLED'
                ? statuses[order.type][order.status]
                : getStatus(
                    order.type,
                    order.status,
                    order.receiptInformation.dateTimeTo,
                  )}
            </p>
          </div>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>
              {order.type === 'COURIER'
                ? order.status === 'DONE'
                  ? t('orders.statusesLabels.courier.DONE')
                  : order.status === 'CANCELLED'
                  ? t('orders.statusesLabels.CANCELLED')
                  : t('orders.statusesLabels.courier.rest')
                : order.status === 'DONE'
                ? t('orders.statusesLabels.goToPlace.DONE')
                : order.status === 'CANCELLED'
                ? t('orders.statusesLabels.CANCELLED')
                : t('orders.statusesLabels.goToPlace.rest')}
            </p>
            <p>
              {order.status === 'CANCELLED'
                ? getTime(order.createDatetime, 0, order.status, true)
                : order.status === 'DONE'
                ? getTime(order.closeDatetime, 0, order.status, true)
                : order.receiptInformation.urgent
                ? getTime(
                    order.receiptInformation.dateTimeTo,
                    order.receiptInformation.deliveryTimeErrorInMinutes,
                    order.status,
                    true,
                  )
                : getTime(
                    order.receiptInformation.dateTimeTo,
                    order.receiptInformation.deliveryTimeErrorInMinutes,
                    order.status,
                    true,
                  )}
            </p>
          </div>
          <div className={style.dataItem}>
            <p className={clsx('caption-1')}>
              {order.type === 'COURIER'
                ? t('orders.captionCourier')
                : t('orders.captionGoToPlace')}
            </p>
            <p>
              {order.type === 'COURIER'
                ? order.receiptInformation.receivingAddress.address
                : order.receiptInformation.receivingRestaurant.name}
            </p>
          </div>
        </div>
        <div className={style.cart}>
          <p className='headline'>{t('orders.details.cartLabel')}</p>
          <div className={style.cartList}>
            {order.dishes.map((dish, i) => (
              <Product
                currency={currency}
                type='DISH'
                product={dish}
                // hard key
                key={dish.product.id + dish.base.id}
              />
            ))}
            {order.constructors.map((constructor, i) => (
              <Product
                currency={currency}
                type='CONSTRUCTOR'
                product={constructor}
                // hard key
                key={constructor.product.id + constructor.base.id}
              />
            ))}
          </div>
        </div>
        <div className={style.pricing}>
          {order.promoCode && (
            <div>
              <p>{t('orders.priceLabel.promocode')}</p>
              <p>{order.promoCode}</p>
            </div>
          )}
          <div className={style.pricingItem}>
            <p>{t('orders.priceLabel.cart')}</p>
            <p>{`${order.price.cart} ${currency}`}</p>
          </div>
          {Boolean(order.price.discount) && (
            <div className={style.pricingItem}>
              <p>{t('orders.priceLabel.discount')}</p>
              <p>{`${order.price.discount} ${currency}`}</p>
            </div>
          )}
          {order.type === 'COURIER' && (
            <div className={style.pricingItem}>
              <p>{t('orders.priceLabel.delivery')}</p>
              <p>{`${order.price.delivery ?? 0} ${currency}`}</p>
            </div>
          )}
          <div className={clsx(style.pricingItem, style.total)}>
            <p>{t('orders.priceLabel.total')}</p>
            <p>{`${order.price.total} ${currency}`}</p>
          </div>
        </div>
      </div>
      <div className={style.submit}>
        <FEButton onClick={() => onRepeatOrder({ order })}>
          {t('orders.details.repeatOrderButton')}
        </FEButton>
      </div>
    </>
  );
};

export default Content;
