import React from 'react';
import { IMG_IC_24_PROFILE_NEW } from 'images';
import style from './style.module.css';
import clsx from 'clsx';
import { MenuProps } from './props';
import { useNavigate } from 'react-router';
import { PROFILE_PATHNAME } from 'const';

const Menu = ({ bonusAccount }: MenuProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  console.log(bonusAccount);
  
  return (
    <div className={style.root} ref={ref}>
      <div
        className={clsx(style.profileContainer)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(PROFILE_PATHNAME);
        }}
      >
        {bonusAccount && <span className={style.bonusLabel}>
          {bonusAccount ? bonusAccount.balance : 0}
        </span>}
        <img src={IMG_IC_24_PROFILE_NEW} alt='' className={style.profileImg} />
      </div>
    </div>
  );
};

export default Menu;
