import React from 'react';
import { IMG_IC_24_BAGNEW } from 'images';
import style from './style.module.css';
import { useUnit } from 'effector-react';
import { $constructors, $dishes, $isValidating, $price } from 'domains/cart';
import { useTranslation } from 'react-i18next';
import { CartModal } from './components/CartModal';
import { $currency } from 'domains/cartography';
import { useNavigate } from 'react-router';
import { useLinks } from 'hooks/router';
import { useClickOutside, useMediaQuery } from 'ui-kit/hooks';
import clsx from 'clsx';

const DesktopCartButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLButtonElement>(null);
  const isDesktop = useMediaQuery(`(min-width: 500px)`);

  const [
    constructors,
    dishes,
    currency,
    isValidating,
    price
  ] = useUnit([
    $constructors,
    $dishes,
    $currency,
    $isValidating,
    $price
  ]);

  const navigate = useNavigate();
  const { cartLink } = useLinks();

  useClickOutside(ref, () => setOpen(false));

  React.useEffect(() => {
    if (!dishes.length && !constructors.length) {
      setOpen(false);
    }
  }, [dishes, constructors]);

  

  return (
    <>
      <button
        ref={ref}
        className={style.cartButton}
        onClick={() => setOpen(!open)}
      >
        <div className={style.profileCartContainer}>
          <img className={clsx(style.cartButtonIcon, 'button-icon-24')} src={IMG_IC_24_BAGNEW} alt='' />
          {constructors.length || dishes.length ? (
            <span className={style.dishCount}>
              {`${
                constructors.reduce(
                  (acc, constructor) => acc + constructor.product.quantity,
                  0,
                ) + dishes.reduce((acc, dish) => acc + dish.product.quantity, 0)
              }`}
            </span>
          ) : null}
          {isDesktop && (
            <p className={clsx(style.cartLabel, 'caption-2')}>{t('catalog.cart.label')}</p>
          )}          
        </div>
      </button>
      <CartModal
        buttonRef={ref}
        currency={currency}
        isPending={isValidating}
        constructors={constructors}
        dishes={dishes}
        open={open}
        price={price}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default DesktopCartButton;
