import FeatSDKV3, { GetAllOrdersResponseV3 } from 'api/version3';
import { createEffect } from 'effector';

type Request = { limit: number; offset: number };

type Response = GetAllOrdersResponseV3.ResponseItem[];

export const getOrdersFx = createEffect<Request, Response>(
  async ({ limit, offset }) => {
    const response = await FeatSDKV3.orders.getAllOrders({ limit, offset });

    return response.items;
  },
);
