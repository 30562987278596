import clsx from 'clsx';
import React from 'react';
import { AddonGroupPickerProps } from './props';
import { BottomSheetContent, Modifier, ModifierCard } from './components';
import style from './style.module.css';
import { MOBILE_WIDTH } from 'const';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEBottomSheet, FEImageComponent } from 'components';
import { CurrencySymbol } from 'models';
import { useTranslation } from 'react-i18next';
import {DishCurrentAddonGroupModifier} from "pages/catalogDesktop/components/dishCard/models";

const AddonGroupPicker = ({
  currentModifiers,
  baseModifiers,
  onPickModifiers,
  name,
  iconId,
  max,
  currency,
}: AddonGroupPickerProps & { currency: CurrencySymbol }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleChangeQuantity = (id: string, quantity: number) => {
    if (quantity) {
      // if (max && currentModifiers.length < max) {
      const newCurrentModifiers = currentModifiers.find(
        (modifier) => modifier.id === id,
      )
        ? currentModifiers.map((modifier) => {
            if (modifier.id === id) {
              modifier.quantity = quantity;
            }
            return modifier;
          })
        : [
            ...currentModifiers,
            {
              ...baseModifiers.find((modifier) => modifier.id === id)!,
              quantity: quantity,
            },
          ];
      onPickModifiers(newCurrentModifiers);
      // }
    } else {
      const newCurrentModifiers = currentModifiers.filter(
        (currentModifier) => currentModifier.id !== id,
      );
      onPickModifiers(newCurrentModifiers);
    }
  };

  const modifiers = React.useMemo(() => {
    const currentModifiersObject = Object.fromEntries(
      currentModifiers.map((currentModifier) => [
        currentModifier.id,
        currentModifier,
      ]),
    );

    return baseModifiers.map((modifier) => {
      const currentModifier: DishCurrentAddonGroupModifier | undefined =
        currentModifiersObject[modifier.id] || undefined;

      if (currentModifier) {
        return {
          ...modifier,
          quantity: currentModifier.quantity,
        };
      }
      return {
        ...modifier,
        quantity: 0,
      };
    });
  }, [currentModifiers, baseModifiers]);

  return !isDesktop ? (
    <>
      {currentModifiers.length === 0 ? (
        <div className={style.root} onClick={() => setOpen(true)}>
          <div className={style.content}>
            {iconId && (
              <FEImageComponent
                className={clsx('icon-24')}
                image={{ imageId: iconId }}
              />
            )}
            <p className='subhead'>{t('catalog.dish.modal.choose')}</p>
            <button
              className={clsx('button-chevronDown', style.chevronButton)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={style.changeButton__container}>
            <button
              className={clsx('headline', style.changeButton)}
              onClick={() => setOpen(true)}
            >
              {t('common.change')}
            </button>
          </div>
          <div className={style.modifiers}>
            {currentModifiers.map((currentModifier, i) => (
              <ModifierCard
                currency={currency}
                quantity={currentModifier.quantity}
                price={currentModifier.price}
                key={currentModifier.id}
                name={currentModifier.info.name}
                imageId={currentModifier.info.imageId}
              />
            ))}
          </div>
        </>
      )}
      <FEBottomSheet
        open={open}
        onClose={() => setOpen(false)}
        render={({ onClose }) => (
          <BottomSheetContent
            currency={currency}
            max={max}
            currentModifiers={currentModifiers}
            baseModifiers={baseModifiers}
            isReachedGroupMax={modifiers.reduce(function (acc, item) {
                return  acc + item.quantity
            }, 0) === max}
            // modifiers={modifiers}
            onClose={onClose}
            name={name}
            onSubmit={(modifiers) => {
              onPickModifiers(
                modifiers.filter((modifier) => modifier.quantity > 0),
              );
            }}
          />
        )}
      />
    </>
  ) : (
    <div className={clsx(style.root, open && style.root__open)}>
      <div
        className={clsx(style.header, open && style.header__open)}
        onClick={() => setOpen(!open)}
      >
        <p className={clsx('subhead', style.name)}>
          {t('catalog.dish.modal.choose')}
        </p>
        <button className={clsx(style.chevronButton, 'button-chevronDown')} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {modifiers
          .filter((md) => (!open ? md.quantity > 0 : md))
          .map((modifier, index) => (
            <Modifier
              currency={currency}
              max={modifier.max}
              key={modifier.id}
              quantity={modifier.quantity}
              isReachedGroupMax={modifiers.reduce(function (acc, item) {
                  return  acc + item.quantity
              }, 0) === max}
              disabled={
                modifier.quantity === 0 && currentModifiers.length === max
              }
              onChangeQuantity={handleChangeQuantity}
              name={modifier.info.name}
              id={modifier.id}
              price={modifier.price}
              imageId={modifier.info.imageId}
            />
          ))}
      </div>
    </div>
  );
};

export default AddonGroupPicker;
