import clsx from 'clsx';
import {
  $isPendingUserUpdate,
  $user,
  profileForm,
  EditProfilePageGate,
  updateUserProfileFx,
} from 'domains/profile';
import { useField } from 'effector-forms';
import { useGate, useUnit } from 'effector-react';
import { Sex } from 'models';
import React, { useEffect } from 'react';
import {
  FEAvatarField,
  FETextField,
  FERadio,
  FEBirthdayPicker,
  FEButton,
  FENotificationCard,
} from 'components';
import style from './style.module.css';
import { PhoneField } from './components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { $language } from 'domains/language';
import { FEHeadersCookiesUtil } from 'utils';

const getValidEmail = (email: string | null) =>
  email
    ? /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    : false;

const Content = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [newBirthday, setNewBirthday] = React.useState<string>('');
  const [openBirthday, setOpenBirthday] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File>();
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [hasAvatarChanged, setHasAvatarChanged] =
    React.useState<boolean>(false);
  useGate(EditProfilePageGate);

  const mask = FEHeadersCookiesUtil.getPhoneMask;

  const language = useUnit($language);

  const { value: firstName, onChange: onChangeFirstName } = useField(
    profileForm.fields.firstName,
  );
  const { value: lastName, onChange: onChangeLastName } = useField(
    profileForm.fields.lastName,
  );
  const { value: sex, onChange: onChangeSex } = useField(
    profileForm.fields.sex,
  );
  const { value: birthday, onChange: onChangeBirthday } = useField(
    profileForm.fields.birthday,
  );
  const { value: email, onChange: onChangeEmail } = useField(
    profileForm.fields.email,
  );
  const { value: phone } = useField(profileForm.fields.phone);

  const [userData, onUpdateUser, isPendingUserUpdate] = useUnit([
    $user,
    updateUserProfileFx,
    $isPendingUserUpdate,
  ]);

  const noChangesFlag = React.useMemo(() => {
    return (
      lastName === userData?.fullName?.lastName &&
      firstName === userData.fullName.firstName &&
      sex === userData.sex &&
      email === userData.email &&
      birthday === userData.birthday &&
      hasAvatarChanged === false
    );
  }, [birthday, email, firstName, hasAvatarChanged, lastName, sex, userData]);

  useEffect(() => {
    if (userData?.sex === 'NOT_SET') {
      onChangeSex('MALE');
    }
  }, []);

  return (
    <>
      <div className={style.root}>
        {/* <div className={style.container}> */}
        {userData?.header && (
          <div className={style.header}>
            <div className={style.headerContent}>
              <p className={clsx('subhead', style.headerMessage)}>
                {userData.header}
              </p>
            </div>
          </div>
        )}
        <div className={style.avatar}>
          <FEAvatarField
            disabled={isPendingUserUpdate}
            avatarId={userData?.avatarId || null}
            onChange={(file) => {
              setFile(file);
              setHasAvatarChanged(true);
            }}
          />
        </div>
        <div className={style.name}>
          <FETextField
            hasError={showErrors && !firstName}
            disabled={isPendingUserUpdate}
            value={firstName}
            onChange={(e) => {
              const val = e.target.value;
              if (val) {
                onChangeFirstName(val);
              } else onChangeFirstName(null);
            }}
            maxLength={20}
            cleareble={false}
            label={t('editProfile.form.firstName')}
          />
          <FETextField
            disabled={isPendingUserUpdate}
            value={lastName}
            hasError={showErrors && !lastName}
            onChange={(e) => {
              const val = e.target.value;
              if (val) {
                onChangeLastName(val);
              } else onChangeLastName(null);
            }}
            cleareble={false}
            maxLength={20}
            label={t('editProfile.form.secondName')}
          />
        </div>
        <div className={style.gender}>
          <FERadio<Exclude<Sex, 'NOT_SET'>>
            disabled={isPendingUserUpdate}
            onChange={(sex) => onChangeSex(sex)}
            values={{
              MALE: {
                label: t('editProfile.form.gender.male'),
                checked: sex === 'MALE',
              },
              FEMALE: {
                label: t('editProfile.form.gender.female'),
                checked: sex === 'FEMALE',
              },
            }}
            label={t('editProfile.form.gender.label')}
          />
        </div>
        <div className={style.fields}>
          <FEBirthdayPicker
            language={language}
            disabled={isPendingUserUpdate}
            hasError={
              showErrors &&
              !(userData?.birthdayModerate
                ? userData.birthdayModerate
                : birthday)
            }
            moderated={
              Boolean(userData?.birthdayModerate)
                ? Boolean(userData!.birthdayModerate)
                : undefined
            }
            value={
              userData?.birthdayModerate ? userData.birthdayModerate : birthday
            }
            onChange={(val) => {
              if (val) {
                if (userData?.birthday) {
                  setNewBirthday(val);
                  setOpenBirthday(true);
                } else {
                  onChangeBirthday(val);
                }
              } else onChangeBirthday(null);
            }}
          />
          <PhoneField
            disabled={isPendingUserUpdate}
            phone={phone}
            mask={mask || ''}
          />
          <FETextField
            hasError={showErrors && !getValidEmail(email)}
            disabled={isPendingUserUpdate}
            value={email}
            onChange={(e) => {
              const val = e.target.value;
              if (val) {
                onChangeEmail(val);
              } else onChangeEmail(null);
            }}
            cleareble={false}
            label='E-mail'
          />
        </div>
      </div>
      <div className={style.submit}>
        <FEButton
          disabled={noChangesFlag || isPendingUserUpdate}
          showLoader={isPendingUserUpdate}
          onClick={() => {
            setShowErrors(true);
            if (
              firstName &&
              getValidEmail(email) &&
              lastName &&
              !!(userData?.birthdayModerate
                ? userData.birthdayModerate
                : birthday)
            ) {
              onUpdateUser({
                firstName,
                lastName,
                sex,
                birthday,
                email,
                image: file,
              }).then(() => onClose());
            }
          }}
        >
          {t('common.save')}
        </FEButton>
        {/* </div> */}
      </div>
      <FENotificationCard
        icon='birthday'
        open={openBirthday}
        onClose={() => {
          setOpenBirthday(false);
        }}
        title={t('common.attention')}
        description={t('editProfile.form.birthday.message', {
          date: moment(newBirthday).format('DD.MM.YYYY'),
        })}
        primaryButtonProps={{
          text: t('common.apply'),
          closeOnClick: true,
          onClick: () => {
            onChangeBirthday(newBirthday);
          },
        }}
        secondaryButtonProps={{
          text: t('common.cancel'),
          closeOnClick: true,
          onClick: () => {},
        }}
      />
    </>
  );
};

export default Content;
