import FeatSDKV3, { GetOrderLongPollResponseV3 } from 'api/version3';
import { createEffect } from 'effector';

type Request = {
  orderId: string;
  timeout?: number;
};

export const subscribeLastActiveOrderFx = createEffect<
  Request,
  GetOrderLongPollResponseV3.Response
>(({ orderId, timeout }) => {
  timeout ??= 25;
  return FeatSDKV3.orders.getOrderLongPoll({ id: orderId, timeout });
});
