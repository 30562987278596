import React, { useCallback } from 'react';
import { FETabButton } from 'components';
import style from './style.module.css';
import { DesktopCartButton } from '..';
import { ConstructorInCartModel, DishInCartModel } from 'domains/cart';
import { OrdersValidateResponseV4 } from 'api/version4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import clsx from 'clsx';
import { CurrencySymbol } from 'models';

const Categories = ({
  categories,
  isDesktop,
  dishes,
  constructors,
  isValidating,
  price,
  currency,
  activeCategoryId,
}: {
  activeCategoryId: string;
  categories: {
    id: string;
    name: string;
    disabled: boolean;
    linkValue: string;
  }[];
  isDesktop: boolean;
  dishes: DishInCartModel[];
  constructors: ConstructorInCartModel[];
  isValidating: boolean;
  price: OrdersValidateResponseV4.OrderPrice | null;
  currency: CurrencySymbol;
}) => {
  const [swiper, setSwiper] = React.useState<SwiperClass>();

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const getCategoryTab = useCallback((categoryId: string) => {
    const el = document.querySelector(
      `div[element-identificator = category][category-id = '${categoryId}']`,
    );
    return {
      scrollTo: () => el?.scrollIntoView({ behavior: 'smooth' }),
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={style.categories}>



      <Swiper
        initialSlide={0}
        mousewheel={true}
        modules={[Mousewheel]}
        direction='vertical'
        slidesPerView={'auto'}
        className={style.categoriesList}
        onSwiper={setSwiper}
      >
        {categories.map((category, i) => {
          const { scrollTo } = getCategoryTab(category.id);
          return (
            <SwiperSlide
              tab-category-id={category.id}
              tab-activity={
                activeCategoryId === category.id ? 'active' : 'non-active'
              }
              key={category.id}
            >
              <FETabButton
                active={activeCategoryId === category.id}
                onChangeActive={(active) => {
                  if (active) {
                    swiper &&
                      swiper.slideTo(i - (isDesktop ? 3 : 1) || 0);
                  }
                }}
                onClick={scrollTo}
                disabled={category.disabled}
              >
                {category.name}
              </FETabButton>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* {categories.map((category, i) => {
                const { scrollTo } = getCategoryTab(category.id);
                return (

                    <FETabButton
                      active={activeCategoryId === category.id}
                      onChangeActive={(active) => {
                        if (active) {
                          swiper &&
                            swiper.slideTo(i - (isDesktop ? 3 : 1) || 0);
                        }
                      }}
                      onClick={scrollTo}
                      disabled={category.disabled}
                    >
                      {category.name}
                    </FETabButton>

                );
              })} */}

      {/*{isDesktop && (*/}
      {/*  <div className={style.cart}>*/}
      {/*    <div className={style.cartButton}>*/}
      {/*      <div className={style.shadowBlock} />*/}
      {/*      <DesktopCartButton*/}
      {/*        currency={currency}*/}
      {/*        price={price}*/}
      {/*        showLoader={isValidating}*/}
      {/*        disabled={constructors.length === 0 && dishes.length === 0}*/}
      {/*        dishes={dishes}*/}
      {/*        constructors={constructors}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>



  );
};

export default Categories;
