import { createStore } from 'effector';
import { pending } from 'patronum';
import {
  getUserProfileFx,
  getSmsCodeFx,
  getBonusAccountFx,
  getBonusAccountTransactionsFx,
  updateUserProfileFx,
  // getOrdersFx,
  initUserFx,
} from './effects';
import {
  User,
  BonusAccount,
  BonusAccountTrasaction,
  ProfileForm,
  Order,
} from './entities';

import { createForm } from 'effector-forms';
import { createGate } from 'effector-react';

export const profileForm = createForm<ProfileForm>({
  fields: {
    firstName: {
      init: null,
    },
    lastName: {
      init: null,
    },
    sex: {
      init: 'NOT_SET',
    },
    birthday: {
      init: null,
    },
    phone: {
      init: null,
    },
    email: {
      init: null,
    },
  },
  validateOn: ['submit'],
});

export const $user = createStore<User | null>(null);

export const $bonusAccount = createStore<BonusAccount | null>(null);

export const $orders = createStore<Order[]>([]);
export const $customerOrdersCount = createStore<number | null>(null);

export const $bonusAccountTransactions = createStore<BonusAccountTrasaction[]>(
  [],
);

export const $isPendingUserAccount = pending({
  effects: [getUserProfileFx],
});

export const $isPendingInitUser = pending({ effects: [initUserFx] });

export const $isPendingBonusAccount = pending({ effects: [getBonusAccountFx] });

export const $isPendingBonusAccountTransactions = pending({
  effects: [getBonusAccountTransactionsFx],
});

export const $isPendingSmsCode = pending({ effects: [getSmsCodeFx] });

export const $isPendingUserUpdate = pending({ effects: [updateUserProfileFx] });

// export const $isPendingOrders = pending({ effects: [getOrdersFx] });

export const EditProfilePageGate = createGate();
export const ordersPageGate = createGate();
