import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru.json';
import en from './locales/en.json';
import hy from './locales/hy.json';
import kz from './locales/kz.json';
import { initLanguageFx } from 'domains/language';
import { INITIAL_LANGUAGE } from 'const';

i18n
  .use(initReactI18next)
  .init({
    lng: INITIAL_LANGUAGE,
    fallbackLng: 'ru',
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
      hy: {
        translation: hy,
      },
      kz: { 
        translation: kz 
      },
    },
    react: {
      defaultTransParent: 'p',
    },
  })
  .then(initLanguageFx);

export default i18n;
