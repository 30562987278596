import {ConstructorCard, DishCard} from "pages/catalogMobile/components";

const CatalogCardFactory = (
    {
        available,
        currency,
        key,
        className,
        stopLists,
        product,
        validationPayload,
        videoClassName,
        quantityInCart,
        changeQuantityInCart,
        type
    }
        : any) => {
    switch (type) {
        case "constructor": {
            return (
                <ConstructorCard
                    available={available}
                    currency={currency}
                    key={key}
                    className={className}
                    stopLists={stopLists}
                    product={product}
                    validationPayload={validationPayload}

                />
            )
        }
        case "dish": {
            return (
                <DishCard
                    available={available}
                    currency={currency}
                    key={key}
                    className={className}
                    stopLists={stopLists}
                    dish={product}
                    validationPayload={validationPayload}
                    videoClassName={videoClassName}
                    quantityInCart={quantityInCart}
                    changeQuantityInCart={changeQuantityInCart}
                />
            )
        }
        default: {
            return null
        }
    }
}

export default CatalogCardFactory