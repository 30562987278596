import React from 'react';
import style from './style.module.css';
import FEButtonProps from './props';
import clsx from 'clsx';
import FESpinner from '../FESpinner';

export default React.forwardRef<HTMLButtonElement, FEButtonProps>(
  (
    {
      className,
      disabled = false,
      type = 'primary',
      showLoader = false,
      onClick,
      children,
      size = 'large',
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          style.root,
          type === 'primary' && style.primary,
          type === 'secondary' && style.secondary,
          type === 'outline' && style.outline,
          size === 'large' && style.large,
          size === 'medium' && style.medium,
          size === 'large' && showLoader && style.withLoaderLarge,
          size === 'medium' && showLoader && style.withLoaderMedium,
          className,
        )}
        disabled={disabled}
        onClick={(e) => {
          onClick(e);
          // e.stopPropagation();
          // e.preventDefault();
        }}
      >
        {showLoader ? (
          <FESpinner
            size={size === 'large' ? 'medium' : 'small'}
            color={type === 'primary' ? 'secondary' : 'primary'}
          />
        ) : (
          children
        )}
      </button>
    );
  },
);

export type { FEButtonProps };
