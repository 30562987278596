import { createEffect, sample } from 'effector';
import { savedCardsApi } from '../api/savedCardsApi';
import { $placeSavedCards } from '../stores';
import { GetSavedCardsResponse } from './../api/GetSavedCardsResponse';

export const getSavedCardsByPlaceIdFx = createEffect<
  string,
  GetSavedCardsResponse
>(savedCardsApi.getSavedCards);

sample({
  clock: getSavedCardsByPlaceIdFx.doneData,
  fn: (res) => res.items,
  target: $placeSavedCards,
});
