import { OrderType } from 'models';
import { PaymentType as PaymentTypeModel } from './types';
import { useCards } from './lib/useCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import style from './PaymentTypesPicker.module.css';
import { Card } from './Card';
import { PaymentType } from './PaymentType';
import { useInsertionEffect, useRef } from 'react';

export const PaymentTypesPicker = ({
  paymentTypes,
  paymentType: currentPaymentType,
  orderType,
  paymentCardId,
  onChange,
}: Props) => {
  const internalChangeCallback = useRef(onChange);

  useInsertionEffect(() => {
    internalChangeCallback.current = onChange;
  }, [onChange]);

  const { cards } = useCards();

  return (
    <Swiper
      className={style.list}
      initialSlide={0}
      spaceBetween={12}
      mousewheel={true}
      modules={[Mousewheel]}
      direction='horizontal'
      slidesPerView={'auto'}
    >
      {paymentTypes.includes('SAVED') &&
        cards.map((card) => (
          <SwiperSlide className={style.slide} key={card.id}>
            <Card
              card={card}
              onClick={() => internalChangeCallback.current('SAVED', card.id)}
              isActive={
                currentPaymentType === 'SAVED' && card.id === paymentCardId
              }
            />
          </SwiperSlide>
        ))}
      {paymentTypes
        .filter((payentType) => payentType !== 'SAVED')
        .map((paymantType) => (
          <SwiperSlide className={style.slide} key={paymantType}>
            <PaymentType
              orderType={orderType}
              paymentType={paymantType as Exclude<PaymentTypeModel, 'SAVED'>}
              onClick={() => internalChangeCallback.current(paymantType, null)}
              isActive={currentPaymentType === paymantType}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

type Props = {
  paymentTypes: PaymentTypeModel[];
  paymentType: PaymentTypeModel | null;
  onChange: (
    paymentType: PaymentTypeModel,
    paymentCardId: string | null,
  ) => void;
  paymentCardId: string | null;
  orderType: OrderType;
  disabled: boolean;
  hasError: boolean;
};
