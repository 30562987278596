import BaseAPIRepository, { BaseAPIResponse } from 'api/base';
import { BASE_URL } from 'const';
import { FERequestErrorsHandler } from 'utils';
import { GetSavedCardsResponse } from './GetSavedCardsResponse';

export const savedCardsApi = {
  getSavedCards: (placeId?: string, signal?: AbortSignal) =>
    new Promise<GetSavedCardsResponse>((resolve, reject) => {
      let url = `${BASE_URL}/api/v1/payment-cards`;

      if (placeId) {
        url += `?placeId=${placeId}`;
      }

      BaseAPIRepository.get<BaseAPIResponse<GetSavedCardsResponse>>(url, {
        signal,
      }).then(
        (result) => resolve(result.data.result),
        (error) => FERequestErrorsHandler(error, reject),
      );
    }),

  deleteSavedCard: (
    { firstSix, lastFour }: { firstSix: string; lastFour: string },
    signal?: AbortSignal,
  ) =>
    new Promise<string>((resolve, reject) => {
      const url = `${BASE_URL}/api/v1/payment-cards?firstSix=${firstSix}&lastFour=${lastFour}`;

      BaseAPIRepository.delete<BaseAPIResponse<string>>(url, {
        signal,
      }).then(
        (result) => resolve(result.data.result),
        (error) => FERequestErrorsHandler(error, reject),
      );
    }),
};
