import clsx from 'clsx';
import style from './NavigateButton.module.css';

export const NavigateButton = ({ onClick, className }: Props) => {
  return (
    <button
      onClick={onClick}
      className={clsx(style.navigateButton, className)}
    />
  );
};

type Props = {
  onClick: () => void;
  className?: string;
};
