import { City, DeliveryZone } from 'domains/cartography';
import { CatalogCategory, CatalogProducts } from 'domains/catalog';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { CurrencySymbol } from 'models';
import { useMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

export const MetaContainer = ({
  currentCity,
  deliveryZone,
  currency,
  categories,
  products,
}: Props) => {
  const match = useMatch('/:name');
  const { t } = useTranslation();

  const { currentCategory, currentProductName } = useMemo(() => {
    if (match) {
      const pathName = match.params.name;

      const currentCategoryFromUrl = categories.find(
        (category) => category.linkValue === pathName,
      );

      if (currentCategoryFromUrl) {
        return {
          currentCategory: currentCategoryFromUrl,
          currentProductName: null,
        };
      } else {
        if (products) {
          const currentProductFromUrl = [
            ...products.dishes,
            ...products.constructors,
          ].find((product) => {
            const productPathname =
              // product.linkValue.counter === 1
              // ?
              product.linkValue.value;
            // : `${product.linkValue.value}${product.linkValue.counter}`
            return productPathname === pathName;
          });

          if (currentProductFromUrl) {
            const currentCategoryFromProduct = categories.find(
              (category) => category.id === currentProductFromUrl.categoryId,
            )!;
            return {
              currentCategory: currentCategoryFromProduct,
              currentProductName: currentProductFromUrl.name,
            };
          }
        }
      }
    }
    return {
      currentCategory: null,
      currentProductName: null,
    };
  }, [categories, products, match]);

  const minFreeDeliveryOrderPrice = useMemo(() => {
    if (
      deliveryZone &&
      deliveryZone.conditions.deliveryPriceConditions.length
    ) {
      const freeDeliveryCondition =
        deliveryZone.conditions.deliveryPriceConditions.find(
          (condition) => condition.deliveryPrice === 0,
        );

      if (freeDeliveryCondition) {
        return `${freeDeliveryCondition.orderTotalMinimum} ${currency}`;
      }
    }
    return null;
  }, [deliveryZone, currency]);

  const receivingDelay = useMemo(
    () => (deliveryZone ? deliveryZone.orderReceivingDelay : null),
    [deliveryZone],
  );

  if (currentCity && currentCategory && currentProductName) {
    return (
      <Helmet>
        <title>
          {t('meta.catalog.withCityAndCategoryAndProduct.title', {
            product: currentProductName,
            category: currentCategory.name,
            city: currentCity.name,
          })}
        </title>
        <meta
          name='description'
          content={t(
            'meta.catalog.withCityAndCategoryAndProduct.metaDescription',
            {
              product: currentProductName,
              city: currentCity.name,
              receivingDelay: receivingDelay
                ? t('meta.catalog.common.receivingDelay', { receivingDelay })
                : ' ',
              freeDeliverySumm: minFreeDeliveryOrderPrice
                ? t('meta.catalog.common.freeDeliverySumm', {
                    freeDeliverySumm: minFreeDeliveryOrderPrice,
                  })
                : ' ',
            },
          )}
        />
      </Helmet>
    );
  }

  if (currentCity && currentCategory) {
    return (
      <Helmet>
        <title>
          {t('meta.catalog.withCityAndCategory.title', {
            category: currentCategory.name,
            city: currentCity.name,
          })}
        </title>
        <meta
          name='description'
          content={t('meta.catalog.withCityAndCategory', {
            city: currentCity.name,
            category: currentCategory.name,
            receivingDelay: receivingDelay
              ? t('meta.catalog.common.receivingDelay', { receivingDelay })
              : ' ',
            freeDeliverySumm: minFreeDeliveryOrderPrice
              ? t('meta.catalog.common.freeDeliverySumm', {
                  freeDeliverySumm: minFreeDeliveryOrderPrice,
                })
              : ' ',
          })}
        />
      </Helmet>
    );
  }

  if (currentCity) {
    return (
      <Helmet>
        <title>
          {t('meta.catalog.withCity.title', { city: currentCity.name })}
        </title>
        <meta
          name='description'
          content={t('meta.catalog.withCity.metaDescription', {
            city: currentCity.name,
            receivingDelay: receivingDelay
              ? t('meta.catalog.common.receivingDelay', { receivingDelay })
              : ' ',
            freeDeliverySumm: minFreeDeliveryOrderPrice
              ? t('meta.catalog.common.freeDeliverySumm', {
                  freeDeliverySumm: minFreeDeliveryOrderPrice,
                })
              : ' ',
          })}
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{t('meta.catalog.default.title')}</title>
      <meta
        name='description'
        content={t('meta.catalog.default.metaDescription')}
      />
    </Helmet>
  );
};

type Props = {
  currentCity: City | null;
  categories: CatalogCategory[];
  products: CatalogProducts | null;
  deliveryZone: DeliveryZone | null;
  currency: CurrencySymbol;
};
